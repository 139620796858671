import React from 'react'
import "./loader.css"

const Loader = () => {
    return (
        <div className='center'>
            <div className="loader">
                <div className="book">
                    <div className="page"></div>
                    <div className="page page2"></div> 
                </div>
            </div>
        </div>
    )
}

export default Loader