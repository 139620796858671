import React, { useEffect } from 'react'
import "./recognition.scss"
import { Link } from 'react-router-dom'
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton'
import startupicon from "../../../../assets/startup-logo.svg"
import arrow from "../../../../assets/arrow_getInTouch.png"
import NIT from "../../../../assets/NIT.svg"

function Recognition({footerR}) {
  useEffect(()=>{
    console.log(footerR)
  },[footerR])

  return (
    <div className='recognition'>
      <div className='left-content'>
        <div className='card'>
           <div className='title'>We have recognition.</div>
          <p className='subtitle'>We are working with Bihar government to implement this idea into every school.</p>
          <div className='btn'>
            {
             !footerR.current && 
             <PrimaryButton title="Sign Up for Free" color="#000000" textcolor="#FFFFFF" img={arrow}/>
            }
            {footerR.current && 
              <PrimaryButton reference={footerR.current.offsetTop} title="Sign Up for Free" color="#000000" textcolor="#FFFFFF" img={arrow}/>
            }
          </div>
        </div>
      </div>
      <div className='right-content'>
        <img src={startupicon} className="startup-logo" alt="Free trial" />
        <div className='NIT'>
            <img src={NIT}></img>
            <p className='desc'>Innovation Center,<br></br>MAHE</p>
        </div>
        
      </div>
    </div>
  )
}

export default Recognition
