import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../../logo.svg";
import "./accountSetup.scss";
import accountSetupDisplay from "../../assets/account-setup-display.svg";
import lamp from "../../assets/lamp.svg";
import profileDetailActiveIcon from "../../assets/personal-detail-active-icon.svg";
import schoolDetailActiveIcon from "../../assets/school-detail-active-icon.svg";
import profileDetailInactiveIcon from "../../assets/personal-detail-inactive-icon.svg";
import schoolDetailInactiveIcon from "../../assets/school-detail-inactive-icon.svg";
import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "../../components/TabSelector/TabSelector";
import { Link, useNavigate } from "react-router-dom";
import { onAuthStateChanged, signInAnonymously, signOut } from "firebase/auth";
import { auth } from "../../services/firebase";
import { db } from "../../services/firebase";
import { async } from "@firebase/util";
import { Student, studentConverter } from "../../models/UserModel";
import { doc, getDoc, setDoc } from "firebase/firestore";

function AccountSetup() {
  let navigate = useNavigate();
  const [err, setErr] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user && auth.currentUser.emailVerified) {
        const uid = user.uid;
        const docRef = doc(db, "users", uid).withConverter(studentConverter);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          var userData = docSnap.data();
          if (userData.age !== "NA") {
            navigate("/library");
          }
        }
      } else if (user && !auth.currentUser.emailVerified) {
        signOut(auth);
        navigate("/login");
      } else {
        navigate("/signup");
      }
    });
  }, []);

  const [personalDetails, setPersonalDetails] = useState({
    phone: "",
    age: 11,
    currentClass: "6",
    goal: "",
  });
  const [schoolDetails, setSchoolDetails] = useState({
    schoolName: "",
    schoolID: "",
    city: "",
    state: "",
  });
  useEffect(() => {
    console.log(personalDetails);
  }, [personalDetails]);

  const handlePersonalDetailsChange = (e) => {
    setPersonalDetails({
      ...personalDetails,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value);
  };

  const handleSchoolDetailsChange = (e) => {
    setSchoolDetails({
      ...schoolDetails,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value);
  };
  const [selectedTab, setSelectedTab] = useTabs([
    "Personal Details",
    "School Details",
  ]);
  const handleNext = async () => {
    if (
      personalDetails.phone == "" ||
      personalDetails.age == "" ||
      personalDetails.currentClass == "" ||
      personalDetails.goal == ""
    ) {
      setErr(true);
    } else {
      setSelectedTab("School Details");
    }
  };
  const handleFinish = async () => {
    if (
      schoolDetails.schoolName == "" ||
      schoolDetails.city == "" ||
      schoolDetails.state == ""
    ) {
      setErr(true);
    } else {
      const docRef = doc(db, "users", auth.currentUser.uid).withConverter(
        studentConverter
      );
      const docSnap = await getDoc(docRef);
      const userData = docSnap.data();

      if (personalDetails.age) {
        userData.age = personalDetails.age;
      }
      userData.currentClass = personalDetails.currentClass;
      userData.goal = personalDetails.goal;
      userData.phone = personalDetails.phone;
      userData.schoolName = schoolDetails.schoolName;
      userData.schoolID = schoolDetails.schoolID;
      userData.city = schoolDetails.city;
      userData.state = schoolDetails.state;

      // const user = new Student({ id: auth.currentUser.uid, fname: auth.currentUser.displayName.split(" ")[0], lname: auth.currentUser.displayName.split(" ").slice(1).join(" "), email: auth.currentUser.email, phone: auth.currentUser.phoneNumber, })
      await setDoc(docRef, userData);
      navigate("/library");
    }
  };

  return (
    <>
      <Helmet>
        <title>Complete Your Account Setup - YourSiteName</title>
        <meta name="description" content="Set up your account with personal and school details to access our services." />
        <meta name="robots" content="noindex, nofollow" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Account Setup",
              "description": "Complete your account setup by providing personal and school details."
            }
          `}
        </script>
      </Helmet>
      <div className="account-setup">
        <div className="left-section">
          <Link to={"/"}>
            <img src={logo} alt="" className="logo" />
          </Link>

          <div className="title">Finish Account Setup</div>
          <div className="subtitle">
            Complete your account setup by providing some details about you.
          </div>
          <div className="card">
            <nav className="tabs">
              <TabSelector
                isActive={selectedTab === "Personal Details"}
                onClick={() => setSelectedTab("Personal Details")}
              >
                <img
                  src={
                    selectedTab === "Personal Details"
                      ? profileDetailActiveIcon
                      : profileDetailInactiveIcon
                  }
                  alt=""
                />
                Personal Details
              </TabSelector>

              <TabSelector
                isActive={selectedTab === "School Details"}
                onClick={() => setSelectedTab("School Details")}
              >
                <img
                  src={
                    selectedTab === "School Details"
                      ? schoolDetailActiveIcon
                      : schoolDetailInactiveIcon
                  }
                  alt=""
                />
                School Details
              </TabSelector>
            </nav>
            <TabPanel hidden={selectedTab !== "Personal Details"}>
              <div className="dropdown-title">Phone Number</div>
              <input
                className="input-feild"
                placeholder="Enter your Phone Number"
                name="phone"
                id="phone"
                value={personalDetails.phone}
                onChange={handlePersonalDetailsChange}
                aria-label="Phone number"
              />
              <div className="dropdown-title">Age</div>
              <input
                className="input-feild"
                placeholder="Enter your Age"
                name="age"
                id="ageList"
                value={personalDetails.age}
                onChange={handlePersonalDetailsChange}
              />
              <div className="dropdown-title">Class</div>
              <select
                className="dropdown"
                name="currentClass"
                id="classList"
                value={personalDetails.currentClass}
                onChange={handlePersonalDetailsChange}
              >
                <option className="dropdown-option" value={6}>
                  VI
                </option>
                <option className="dropdown-option" value={7}>
                  VII
                </option>
                <option className="dropdown-option" value={8}>
                  VII
                </option>
                <option className="dropdown-option" value={9}>
                  IX
                </option>
                <option className="dropdown-option" value={10}>
                  X
                </option>
                <option className="dropdown-option" value={11}>
                  XI
                </option>
                <option className="dropdown-option" value={12}>
                  XII
                </option>
              </select>
              <div className="dropdown-title">Goal</div>
              <input
                className="input-feild"
                name="goal"
                type="text"
                placeholder="What do you want to become in life?"
                value={personalDetails.goal}
                onChange={handlePersonalDetailsChange}
              />
              {err && (
                <p className="errorMsg" style={{ color: "white" }}>
                  Attention, All fields need to be filled!!
                </p>
              )}
              <button className="next-button" onClick={handleNext}>
                {" "}
                Next{" "}
              </button>
            </TabPanel>
            <TabPanel hidden={selectedTab !== "School Details"}>
              <div className="dropdown-title">Name of your school</div>
              <input
                className="input-feild"
                name="schoolName"
                type="text"
                placeholder="School Name"
                value={schoolDetails.schoolName}
                onChange={handleSchoolDetailsChange}
              />
              <div className="dropdown-title">Referral ID</div>
              <input
                className="input-feild"
                name="schoolID"
                type="text"
                placeholder="(if any provided by the school)"
                value={schoolDetails.schoolID}
                onChange={handleSchoolDetailsChange}
              />
              <div className="dropdown-title">Location</div>
              <input
                className="input-feild"
                name="city"
                type="text"
                placeholder="City"
                value={schoolDetails.city}
                onChange={handleSchoolDetailsChange}
              />
              <input
                className="input-feild"
                name="state"
                type="text"
                placeholder="State"
                value={schoolDetails.state}
                onChange={handleSchoolDetailsChange}
              />
              {err && (
                <p className="errorMsg" style={{ color: "white" }}>
                  Attention, All fields need to be filled!!
                </p>
              )}
              <button className="next-button" onClick={handleFinish}>
                {" "}
                Finish{" "}
              </button>
            </TabPanel>
          </div>
        </div>
        <div className="right-section">
          <img className="setDisplayPic" src={accountSetupDisplay} alt="" />
          <div className="sub-title">
            We take your privacy and security seriously, and we'll never share
            your personal information with third parties.
          </div>
        </div>
        <img src={lamp} alt="Decorative lamp icon for design enhancement" className="lamp" />
      </div>
    </>
  );
}

export default AccountSetup;
