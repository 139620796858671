import './sideBar.scss'
import logo from '../../../assets/sidebar-logo.svg'
import libratyIcon from '../../../assets/library.svg'
import dashboardIcon from '../../../assets/dashboardicon.svg'
import profilelogo from '../../../assets/profileicon.svg'
import logouticon from '../../../assets/logouticon.png'
import { Link,useLocation } from 'react-router-dom'
import { useState,useEffect } from 'react'
import { signOut } from 'firebase/auth'
import { auth } from '../../../services/firebase'
import hamburger from '../../../assets/hamburger.svg'



function NavBarMob({ children,p }) {
    const handleSignOut = () => {
        signOut(auth)
    }
    const [vis,setVis] = useState('hidden')
    const location = useLocation()
    const [currentRoute, setCurrentRoute] = useState('');

    useEffect(() => {
        setCurrentRoute(location.pathname);
      }, [location]);

    return (
        <div className='navBar'>
            <div className='popUpWindow' style={{visibility:`${vis}`}}>
                <img src={logo}></img>
                <button className='more' onClick={()=>{setVis('hidden')}} style={{color:'#FCEE21'}}>☰</button>
                <div className='links'>
                {
                    !p && 
                    <Link className='nav-button' to="/dashboard">
                        <img src={dashboardIcon} alt=""></img>
                        {currentRoute == '/dashboard' &&
                            <p style={{color:'green'}}>Dashboard</p>
                        }
                        {currentRoute != '/dashboard' && 
                            <p>Dashboard</p>
                        }
                    </Link>
                }
                {
                    !p && 
                    <>
                    <Link className='nav-button' to="/library">
                    <img src={libratyIcon} alt="" style={{filter:'grayscale(100%)'}}></img>
                    {currentRoute == '/library' &&
                        <p style={{color:'orange'}}>Library</p>
                    }
                    {currentRoute != '/library' && 
                        <p>Library</p>
                    }
                </Link>
                <Link className='nav-button' to="/profile">
                    <img src={profilelogo} alt=""></img>
                    {currentRoute == '/profile' &&
                        <p style={{color:'red'}}>Profile</p> 
                    }
                    {currentRoute != '/profile' && 
                        <p>Profile</p>
                    }
                </Link>
                    </>
                }
                    <Link className='logout-button' onClick={handleSignOut}>
                        <img src={logouticon} alt="" />
                        <p>Logout</p>
                    </Link>
                </div>
            </div>
            <div className='main'>
                <img src={logo}></img>
                <button className='more' onClick={()=>{setVis('visible')}} style={{color:'#FCEE21'}}>☰</button>
            </div>
        </div>
    )
}

export default NavBarMob
