import React, { useCallback, useContext, useEffect,useState } from 'react'
import './dashboard.scss'
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, db } from '../../services/firebase';
import { studentConverter } from '../../models/UserModel';
import {getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import SideBar from './SideBar/SideBar';
import NavBarMob from './SideBar/NavBarMob';
import { collection, orderBy, query, updateDoc, where } from "firebase/firestore";
import { getDocs, doc ,setDoc } from "firebase/firestore";
import { Authcontext } from '../../contextProvider';
import defaultPic from '../../assets/defaultPic.svg'
import edit from '../../assets/Vector.svg'
import tick from '../../assets/Vector.png'
import upload from '../../assets/uploadIcon.svg'
import arr from '../../assets/arrow_.svg'
import pg1 from '../../assets/pg1.svg'
import pg3 from '../../assets/pg3.svg'
import pg4 from '../../assets/pg4.svg'
import { ref, uploadBytesResumable } from "firebase/storage";
// import { storage } from '../../firebaseconfig';
import { storage } from '../../services/firebase';
import { getDownloadURL } from "firebase/storage";
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { sendEmailVerification } from 'firebase/auth'

function Dashboard() {

  let navigate = useNavigate();
  const [userData,setUD] = useState({})
  const [editData,setEditData] = useState('')
  const {currentUser} = useContext(Authcontext)
  const [windowWidth, setWindowWidth] = useState(window.outerWidth);
  const [newVal,setNewVal] = useState()
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterPass,setRePass] = useState("")
  const [pg,setPg] =  useState(1);
  const [feedback,setFeedBack] = useState("");
  const userDataRef = collection(db, "users");

  
  useEffect(()=>{
    if(currentUser){
      // onAuthStateChanged(auth, async(user)=>{
      //   if(user && !auth.currentUser.emailVerified){
      //       alert("Verification email has been sent. Please verify your email before signing in again!")
      //       sendEmailVerification(auth.currentUser)
      //       .then(()=>{
      //           signOut(auth);
      //       })
      //       .catch((err)=>{
      //           console.log(err)
      //       })
      //   }
      // })
      if(!currentUser.emailVerified){
        signOut(auth);
      }
    }
    const fetchUserInfo =async()=>{
      const q1 = query(userDataRef,where('id','==',`${currentUser.uid}`))
      let temp1 = []
      const querySnapShot1 = await getDocs(q1)
      try {
          querySnapShot1.forEach((doc) => {
              temp1.push(doc.data())
          })
      } catch (err) {
          console.log(err)
      }      
      setUD(temp1[0])
      console.log(temp1[0])
    }
    fetchUserInfo()
  },[])
  useEffect(()=>{
    if(editData != ''){
      setNewVal(editData)
      console.log(editData)
    }
  },[editData])
  // useEffect(()=>{
  //   console.log(demo)
  // },[demo])

//   useEffect(() => {
//     console.log(windowWidth)
//     onAuthStateChanged(auth, async (user) => {
//         if (user) {
//             const uid = user.uid;
//             console.log("uid", uid)
//             const docRef = doc(db, "users", uid).withConverter(studentConverter);
//             const docSnap = await getDoc(docRef);
//             if (docSnap.exists()) {
//                 var userData =  docSnap.data();
//                 if (userData.age === 'NA') {
//                     navigate('/accountSetup');
//                 }
//             } 
//         } else {
//             console.log("user is logged out")
//             navigate('/login');

//         }
//     });

// }, [])

const handleChangePassword = async () => {
  if(newPassword == reEnterPass){
    try {
      // Reauthenticate the user with their current password
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      await reauthenticateWithCredential(user, credential);
  
      // Update the user's password
      await updatePassword(user, newPassword);
  
      // Password changed successfully
      alert("Password changed successfully!");
    } catch (error) {
      console.error(error);
      alert("Password change failed. Please try again.");
    }
  }
  else{
    alert("New and Current passwords do not match!");
    setRePass("")
    setNewPassword("")
  }
}

const handleSubmit =  async(parameter) =>{
  if(newVal != userData[parameter]){
    await updateDoc(doc(db, "users", currentUser.uid), {
      [parameter]:newVal
    }).then(()=>{
      let temp = userData
      temp[parameter] = newVal  
      setEditData('')
      setNewVal('')
    })
  }
  else{
    setNewVal('')
    setEditData('')
  }
}

const setProfilePic = async(e) =>{
  const file = e.target.files[0];
  const storageRef = ref(storage,`User-Profile-Pictures/${currentUser.displayName}`)
        await uploadBytesResumable(storageRef,file)
            .then(()=>{
                getDownloadURL(storageRef).then(async (downloadURL) => {
                  let temp = userData;
                  temp = {...temp,profilePic:downloadURL}
                    try{
                        await setDoc(doc(db, "users", currentUser.uid), {
                          age
                          :userData.age,
                          city
                          :userData.city,
                          currentClass
                          :userData.currentClass,
                          email
                          :userData.email,
                          fname
                          :userData.fname,
                          goal
                          :userData.goal,
                          id
                          :userData.id,
                          lname
                          :userData.lname,
                          phone
                          :userData.phone,
                          profilePic
                          :downloadURL,
                          schoolName
                          :userData.schoolName,
                          state
                          :userData.state
                        });
                    }
                    catch(err){
                        console.log(err)
                    }
            })
        })
}

  return (
    <div className='dashboard'> 
    {
      windowWidth > 768 &&
      <SideBar p = {false}/>
    }
    {
      windowWidth <= 768 && 
      <NavBarMob p = {false}/>
    }
        <div className='dashboard-content'>

            <div className='userInfo'>
                    {
                      userData.profilePic && 
                      <img src={userData.profilePic}></img>
                    }
                    {
                      !userData.profilePic && 
                      <img src={defaultPic}></img>
                    } 
              <div className='details'>
                <p className='name'>{userData.fname}</p>
                <p className='class'>CLASS 6</p>
              </div>
            </div>
            <div className='intro'>
              <p className='heading'>Account Details</p>
              <p className='desc'>Update your profile picture and details here</p>
            </div>
            <div className='hr'> </div>
            
            <div className='dts'>


              <div className='detailsList'>
                <div className='intro'>
                    <p className='heading'>Public Profile</p>
                    <p className='desc'>This will be displayed on your profile</p>
                </div>
                <div className='col2'>
                  <div className='dt'>
                    <p className='DH'>Name</p>
                    {
                      editData[0] && editData == userData.fname &&
                      <input onChange={(e)=>{setNewVal(e.target.value)}} value={newVal} autoFocus
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                              handleSubmit('fname');
                          }
                        }}
                        onBlur={()=>{handleSubmit('fname')}}
                    ></input>
                    }
                    {
                      editData != userData.fname && 
                      <p className='val'>{userData.fname}</p>
                    }
                    {
                      editData != userData.fname && 
                      <button onClick={()=>{setEditData(userData.fname)}}><img src={edit}></img></button>
                    }
                    {
                      editData[0] && editData == userData.fname &&
                      <button onClick={()=>{handleSubmit('fname')}} style={{borderColor:'#A1991D'}}><img src={tick}></img></button>
                    }
                  </div>
                  {/* <div className='dt'>
                    <p className='DH'>Email</p>
                    {
                      editData[0] && 
                      <input onChange={(e)=>{setEditData(e.target.value)}} value={editData}></input>
                    }
                    {
                      editData[0] && 
                      <p className='val'>{userData.fname}</p>
                    }
                  </div> */}
                  <div className='dt'>
                    <p className='DH'>Phone</p>
                    {
                      editData[0] && editData == userData.phone &&
                      <input onChange={(e)=>{setNewVal(e.target.value)}} value={newVal} 
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                              handleSubmit('phone');
                          }
                        }}
                        onBlur={()=>{handleSubmit('phone')}}
                      autoFocus></input>
                    }
                    {
                      editData != userData.phone &&
                      <p className='val'>{userData.phone}</p>
                    }
                    {
                      editData != userData.phone && 
                      <button onClick={()=>{setEditData(userData.phone)}}><img src={edit}></img></button>
                    }
                    {
                      editData[0] && editData == userData.phone &&
                      <button onClick={()=>{handleSubmit('phone')}} style={{borderColor:'#A1991D'}}><img src={tick}></img></button>
                    }
                  </div>
                  <div className='dt'>
                    <p className='DH'>Email</p>
                    {
                      editData != userData.email &&
                      <p className='val'>{userData.email}</p>
                    }
                  </div>
                </div>
              </div>

              <hr className='hr'></hr>


              <div className='detailsList'>
                <div className='intro'>
                    <p className='heading'>Profile picture</p>
                    <p className='desc'>This will be displayed on your profile</p>
                </div>
                <div className='col2'>
                  <div className='dtImg'>
                    {
                      userData.profilePic && 
                      <img src={userData.profilePic} className='profilePic'></img>
                    }
                    {
                      !userData.profilePic && 
                      <img src={defaultPic}></img>
                    }
                    <input type='file' id='file' onChange={(e)=>{setProfilePic(e)}} style={{display:'none'}}></input>
                    <label htmlFor='file'>
                      <img src={upload}></img>
                      <p>Click Icon to upload New profile photo</p>
                    </label>
                  </div>
                </div>
              </div>

              <hr className='hr'></hr>

              <div className='detailsList'>
                <div className='intro'>
                    <p className='heading'>Password</p>
                    <p className='desc'>Change your password</p>
                </div>
                <div className='col2'>
                  <div className='dt'>
                    <input className='val2' placeholder='Current Password' onChange={(e)=>{setCurrentPassword(e.target.value)}} style={{}}></input>
                  </div>
                  <div className='dt'>
                    <input className='val2' placeholder='New Password' onChange={(e)=>{setNewPassword(e.target.value)}}></input>
                  </div>
                  <div className='dt'>
                    <input className='val2' placeholder='Re-Enter Password' onChange={(e)=>{setRePass(e.target.value)}}></input>
                  </div>
                  <button className='subBtnP' onClick={()=>{handleChangePassword()}}>Submit</button>
                </div>
              </div>


              <hr className='hr'></hr>

              <div className='detailsList'>
                <div className='intro'>
                    <p className='heading'>Personal Details</p>
                    <p className='desc'>This will be displayed on your profile</p>
                </div>
                <div className='col2'>
                  <div className='dt'>
                    <p className='DH'>Class</p>

                    <p className='val'>{userData.currentClass}</p>
                  </div>
                  
                  <div className='dt'>
                    <p className='DH'>Age</p>
                    {
                      editData && editData == userData.age &&
                      <input onChange={(e)=>{setNewVal(e.target.value)}} value={newVal} 
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                              handleSubmit('age');
                          }
                        }}
                        onBlur={()=>{handleSubmit('age')}}
                      autoFocus></input>
                    }
                    {
                      editData != userData.age &&
                      <p className='val'>{userData.age}</p>
                    }
                    {
                      editData != userData.age && 
                      <button onClick={()=>{setEditData(userData.age)}}><img src={edit}></img></button>
                    }
                    {
                      editData[0] && editData == userData.age &&
                      <button onClick={()=>{handleSubmit('age')}} style={{borderColor:'#A1991D'}}><img src={tick}></img></button>
                    }
                    
                  </div>

                  <div className='dt'>
                    <p className='DH'>Goal</p>
                    {
                      editData[0] && editData == userData.goal &&
                      <input onChange={(e)=>{setNewVal(e.target.value)}} value={newVal} 
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                              handleSubmit('goal');
                          }
                        }}
                        onBlur={()=>{handleSubmit('goal')}}
                      autoFocus></input>
                    }
                    {
                      editData != userData.goal &&
                      <p className='val'>{userData.goal}</p>
                    }
                    {
                      editData != userData.goal && 
                      <button onClick={()=>{setEditData(userData.goal)}}><img src={edit}></img></button>
                    }
                    {
                      editData[0] && editData == userData.goal &&
                      <button onClick={()=>{handleSubmit('goal')}} style={{borderColor:'#A1991D'}}><img src={tick}></img></button>
                    }
                  </div>
                </div>
              </div>

              <hr className='hr'></hr>

              <div className='detailsList'>
                <div className='intro'>
                    <p className='heading'>School Details</p>
                    <p className='desc'>This will be displayed on your profile</p>
                </div>
                <div className='col2'>
                  
                  <div className='dt'>
                    <p className='DH'>Name</p>
                    {
                      editData[0] && editData == userData.schoolName &&
                      <input onChange={(e)=>{setNewVal(e.target.value)}} value={newVal} 
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                              handleSubmit('schoolName');
                          }
                        }}
                        onBlur={()=>{handleSubmit('schoolName')}}
                      autoFocus></input>
                    }
                    {
                      editData != userData.schoolName &&
                      <p className='val'>{userData.schoolName}</p>
                    }
                    {
                      editData != userData.schoolName && 
                      <button onClick={()=>{setEditData(userData.schoolName)}}><img src={edit}></img></button>
                    }
                    {
                      editData[0] && editData == userData.schoolName &&
                      <button onClick={()=>{handleSubmit('schoolName')}} style={{borderColor:'#A1991D'}}><img src={tick}></img></button>
                    }
                  </div>

                  <div className='dt'>
                    <p className='DH'>City</p>
                    {
                      editData[0] && editData == userData.city &&
                      <input onChange={(e)=>{setNewVal(e.target.value)}} value={newVal} 
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                              handleSubmit('city');
                          }
                        }}
                        onBlur={()=>{handleSubmit('city')}}
                      autoFocus></input>
                    }
                    {
                      editData != userData.city &&
                      <p className='val'>{userData.city}</p>
                    }
                    {
                      editData != userData.city && 
                      <button onClick={()=>{setEditData(userData.city)}}><img src={edit}></img></button>
                    }
                    {
                      editData[0] && editData == userData.city &&
                      <button onClick={()=>{handleSubmit('fname')}} style={{borderColor:'#A1991D'}}><img src={tick}></img></button>
                    }
                  </div>

                  <div className='dt'>
                    <p className='DH'>State</p>
                    {
                      editData[0] && editData == userData.state &&
                      <input onChange={(e)=>{setNewVal(e.target.value)}} value={newVal} 
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                              handleSubmit('state');
                          }
                        }}
                        onBlur={()=>{handleSubmit('state')}}
                      autoFocus></input>
                    }
                    {
                      editData != userData.state &&
                      <p className='val'>{userData.state}</p>
                    }
                    {
                      editData != userData.state && 
                      <button onClick={()=>{setEditData(userData.state)}}><img src={edit}></img></button>
                    }
                    {
                      editData[0] && editData == userData.state &&
                      <button onClick={()=>{handleSubmit('fname')}} style={{borderColor:'#A1991D'}}><img src={tick}></img></button>
                    }
                  </div>

                  {/* <div className='dt'>
                    <p className='DH'>School ID</p>
                    {
                      editData[0] && editData == userData.city &&
                      <input onChange={(e)=>{setEditData(e.target.value)}} value={editData}></input>
                    }
                    {
                      !editData[0] && editData != userData.city &&
                      <p className='val'>{userData.city}</p>
                    }
                    <button onClick={()=>{setEditData(userData.city)}}></button>
                  </div> */}

                </div>
              </div>


            </div>
        </div>
    </div>
  )
}

export default Dashboard