import { createContext, useEffect, useState } from "react";
import { auth } from "./services/firebase";
import { onAuthStateChanged, signOut, sendEmailVerification} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { collection, orderBy, query, updateDoc, where } from "firebase/firestore";
import { getDocs, doc } from "firebase/firestore";
import { db } from "./services/firebase";

export const Authcontext = createContext()
export const AuthcontextProvider =({children})=>{
    // const chaptersRef = collection(db, "chapters");
    const [currentUser,setCurrentUser] = useState({})
    const [ch,setch] = useState([]);
    const [currentChapter,setCC] = useState({})
    const [loading,setLoading] = useState(false);
    const [userDat,setU] = useState([]);
    const [User,setUser] = useState([]);
    const userRef = collection(db,'users')

    useEffect(()=>{
        const unsub = onAuthStateChanged(auth,(user)=>{
            setCurrentUser(user)
            if(user && user.uid){
                fetchUserChaps(user)
            }
            console.log(user);
        })
        let CC =  JSON.parse(localStorage.getItem('currentChapter'))
        if(CC){
            setCC(CC);
        }
        const fetchChapters = async (user)=>{

            if(user){
                const q = query(collection(db, "chapters"),where('class','==',user.currentClass),where('class','==',`${user.currentClass}`))
                const temp = []
                const querySnapShot = await getDocs(q)
                try {
                    querySnapShot.forEach((doc) => {
                        temp.push(doc.data())
                    })
                    console.log(temp)
                    setch(temp)
                } catch (err) {
                    console.log(err)
                }
            }
            
        }
        const fetchUserChaps = async(user)=>{
            const q1 = query(userRef,where('id','==',`${user.uid}`))
            let temp1 = []
            const querySnapShot1 = await getDocs(q1)
            try {
                querySnapShot1.forEach((doc) => {
                    temp1.push(doc.data())
                })
                setUser(temp1[0])
                fetchChapters(temp1[0]); 
                console.log(temp1)
            } catch (err) {
                console.log(err)
            }
          }
        return ()=>{
            unsub();
        }
    },[]);


    return(
        <Authcontext.Provider value={{currentUser,currentChapter,setCC,loading,setLoading,ch,setch,userDat,setU}}>
            {children}
        </Authcontext.Provider>
    )
    
}