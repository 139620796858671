import './howRight.scss'
import React from 'react'
import divider from '../../../../../assets/Divider.svg'
import Lottie from 'react-lottie';

function HowRight({ heading, number, color, video, text }) {

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true, 
  //   muted, 
  //   playdInline,
  //   loading:"lazy",
  // };

  return (
    <>

      <div className='howRight' >
        <div className='row'>
          <div className='number'>{number}
            <div className='circle' style={{ "background-color": color }} />
            <div className='heading'>{heading}</div>
          </div>
        </div>
        
        <div className='column'>
          <div className='images'>
  
            {/* <Lottie
              options={defaultOptions}
              height={'30vh'}
                width={'30vw'}
              // className="images"
            /> */}
            <video 
              src={video}
              className='gif' 
              autoPlay
              playsInline
              muted
              loop
              loading="lazy"
            />
          </div>
        </div>
      

        <div className='paratext'>{text}</div>
      </div>

    </>
  )
}

export default HowRight
