import { Authcontext } from "../../../contextProvider";
import SideBar from "../SideBar/SideBar";
import "./profile.scss";

import objects from "../../../assets/OBJECTS.svg";
import btn from "../../../assets/profileResumeBtn.png";
import ChartIcon from "../../../assets/chartIcon.svg";
import rocketProfile from "../../../assets/rocketProfile.png";
import MHicon from "../../../assets/mentalHealthIcon.png";
import analytics from "../../../assets/ion_analytics-outline.svg";
import NavBarMob from "../SideBar/NavBarMob";
import DynamicChart from "./DynamicChart";
import free from "../../../assets/FREE.svg";
import premuim from "../../../assets/Vectorcrown.svg";

import defaultPic from "../../../assets/defaultPic.svg";
import arrow2 from "../../../assets/Vector2.svg";
import arrow1 from "../../../assets/Vector(2).svg";
import arrow3 from "../../../assets/Vector(1).svg";
import star from "../../../assets/_.svg";
import one from "../../../assets/1.png";
import two from "../../../assets/2.png";
import three from "../../../assets/3.png";
import four from "../../../assets/4.png";
import five from "../../../assets/5.png";
import six from "../../../assets/6.png";
import seven from "../../../assets/7.png";
import eight from "../../../assets/8.png";
import nine from "../../../assets/9.png";
import ten from "../../../assets/10.png";
import eleven from "../../../assets/11.png";
import twelve from "../../../assets/12.png";
import arr from "../../../assets/arrow_.svg";
import pg1 from "../../../assets/pg1.svg";
import pg3 from "../../../assets/pg3.svg";
import pg4 from "../../../assets/pg4.svg";

import s1 from "../../../assets/s1.svg";
import s1b from "../../../assets/s1b.svg";
import s2 from "../../../assets/s2.svg";
import s2b from "../../../assets/s2b.svg";
import s3 from "../../../assets/s3.svg";
import s3b from "../../../assets/s3b.svg";
import s4 from "../../../assets/s4.svg";
import s4b from "../../../assets/s4b.svg";
import s5 from "../../../assets/s5.svg";
import s5b from "../../../assets/s5b.svg";

import React, { useContext, useEffect, useState } from "react";
import {
  collection,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDocs, getDoc, doc } from "firebase/firestore";
import { db } from "../../../services/firebase";
import { cashfree } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../services/firebase";
import {
  onAuthStateChanged,
  sendEmailVerification,
  signOut,
} from "firebase/auth";

function Profile() {
  const { currentUser } = useContext(Authcontext);
  const [totalRecTps, setTRT] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.outerWidth);
  const [topics, setTopics] = useState([]);
  const { ch, setch } = useContext(Authcontext);
  const [strongTopics, setSTopics] = useState([]);
  const [weakTopics, setWeakTopics] = useState([]);
  const [userChaps, setUC] = useState([]);
  const { currentChapter, setCC } = useContext(Authcontext);
  const userDataRef = collection(db, "user_data");
  const userDataRef1 = collection(db, "users");
  const chapDataRef = collection(db, "chapters");
  const [imgs, setImgs] = useState([
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
  ]);
  const [userInfo, setUI] = useState([]);
  const [userData, setUD] = useState([]);
  const [userData2, setUD2] = useState([]);
  const [t, setT] = useState(false);
  const [t2, setT2] = useState(false);
  const [marks, setMarks] = useState();
  const [popUp, setPopUp] = useState(false);
  const [sname, setSN] = useState();
  const [wname, setWN] = useState();
  const [per, setPer] = useState(0);
  const navigate = useNavigate();
  const [p, setP] = useState(0);
  const [enrolledChapters, setEC] = useState([]);
  const [barGraph, setBG] = useState([]);
  const [arcAngle, setAA] = useState(0);

  const [pg, setPg] = useState(0);
  const [feedback, setFeedBack] = useState("");
  const [pos, setPos] = useState(50);

  useEffect(() => {
    if (ch) {
      const n = new Array(ch.length).fill(0);
      setBG(n);
    }
  }, [ch]);
  useEffect(() => {
    const curTime = new Date().getTime();
    if (userData2 && userData2.fname) {
      if (userData2.mentalHealth) {
        setAA(
          (userData2.mentalHealth.mood / 5) * 100 * 4 -
          (5 * userData2.mentalHealth.mood) / 5
        );
      }
      if (
        !userData2.mentalHealth ||
        curTime - userData2.mentalHealth.lastTime.seconds * 1000 >
        24 * 60 * 60 * 1000
      ) {
        setPg(1);
      }
    }
  }, [userData2]);
  useEffect(() => {
    if (userData.enrolled_Chapters) {
      let temp = userData;
      // temp.enrolled_Chapters = enrolledChapters
      setUD(temp);
      console.log(temp);
      console.log(enrolledChapters);
      setT2(true);
    }
  }, [enrolledChapters]);
  useEffect(() => {
    if (currentUser) {
      if (!currentUser.emailVerified) {
        signOut(auth);
      }
    }
    const fetchUserInfo = async () => {
      const q1 = query(userDataRef1, where("id", "==", `${currentUser.uid}`));
      let temp1 = [];
      const querySnapShot1 = await getDocs(q1);
      try {
        querySnapShot1.forEach((doc) => {
          temp1.push(doc.data());
        });
      } catch (err) {
        console.log(err);
      }
      setUD2(temp1[0]);
    };
    fetchUserInfo();
  }, []);
  useEffect(() => {
    if (p === 0) {
      fetchData();
    }
  }, [p]);
  const temp = [];
  let tem = [];
  const fetchData = async () => {
    const str = currentUser.uid;
    const q = query(userDataRef, where("id", "==", `${str}`));
    const querySnapShot = await getDocs(q);

    try {
      querySnapShot.forEach((doc) => {
        temp.push(doc.data());
      });

      // Extract and convert the numeric part of chap_id for sorting
      temp[0].enrolled_Chapters.sort((a, b) => {
        const numberA = parseInt(a.chap_id.match(/\d+/)[0], 10);
        const numberB = parseInt(b.chap_id.match(/\d+/)[0], 10);

        return numberA - numberB; // Compare numeric values
      });

      setP(1);
      for (let i = 0; i < temp[0].enrolled_Chapters.length; i++) {
        tem = [...tem, temp[0].enrolled_Chapters[i]];
      }
      console.log(tem);
      console.log(temp[0].enrolled_Chapters[1]);
      console.log(temp[0]);
    } catch (err) {
      console.log(err);
    }
    let a2 = [...tem.slice(0, tem.length / 2)];
    setEC(a2);
    setUD(temp[0]);
  };

  useEffect(() => {
    if (t2) {
      for (let i = 0; i < userData.enrolled_Chapters.length; i++) {
        if (
          userData.enrolled_Chapters[i].chap_id ==
          userData.current_chap.chapter.chap_id
        ) {
          setPer(
            (userData.enrolled_Chapters[i].completion /
              userData.current_chap.chapter.content.length) *
            100
          );
          break;
        }
      }
      let temp = enrolledChapters;
      let temp2 = [];
      for (let i = 0; i < ch.length; i++) {
        let p = temp2.length;
        for (let j = 0; j < temp.length; j++) {
          if (ch[i].chap_id == temp[j].chap_id) {
            temp2 = [...temp2, temp[j]];
            temp.splice(1, j);
            break;
          }
        }
        if (p == temp2.length) {
          temp2 = [
            ...temp2,
            {
              chap_id: ch[i].chap_id,
              checkpointResults: [],
              completion: 0,
              status: 0,
            },
          ];
        }
      }

      temp2.sort((a, b) => {
        const numberA = parseInt(a.chap_id.match(/\d+/)[0], 10);
        const numberB = parseInt(b.chap_id.match(/\d+/)[0], 10);

        return numberA - numberB; // Compare numeric values
      });
      console.log(temp2);
      setUC(temp2);
      setT(true);
    }
  }, [t2]);
  useEffect(() => {
    console.log(barGraph);
  }, [barGraph]);
  useEffect(() => {
    if (t) {
      let recTopics = [];
      let length = ch.length;
      let a = Array.from({ length }, () => 0);
      let b = Array.from({ length }, () => 0);

      let st = [];
      let be = [];
      let tot = 0;
      let bag = barGraph;
      for (let i = 0; i < userData.enrolled_Chapters.length; i++) {
        let p = userData.enrolled_Chapters[i].checkpointResults;
        let ab60 = 0;
        let be60 = 0;
        let temp1 = [];
        let temp2 = [];
        let temp = [];
        let avg = 0;
        const chapterNumber =
          userData.enrolled_Chapters[i].chap_id.match(/\d+/)[0];
        for (let j = 0; j < p.length; j++) {
          if (p[j].score <= 60) {
            temp = [...temp, { chapNo: chapterNumber, topic: p[j].topic }];
            temp1 = [...temp1, p[j].topic];
            be60++;
          } else if (p[j].score > 60) {
            ab60++;
            temp2 = [...temp2, p[j].topic];
          }
          avg = avg + p[j].score;
        }
        if (p.length > 0) {
          avg = Math.floor(avg / p.length);
          console.log(userData.enrolled_Chapters[i].chap_id);
          const Number = chapterNumber;
          bag[Number] = avg;
        }
        tot = tot + be60;
        a[chapterNumber] = ab60;
        b[chapterNumber] = be60;
        if (temp2.length > 0) {
          st = [...st, { chapName: `Chapter-${chapterNumber}`, topics: temp2 }];
        }
        if (temp1.length > 0) {
          be = [...be, { chapName: `Chapter-${chapterNumber}`, topics: temp1 }];
        }
        if (temp.length > 0) {
          recTopics = [
            ...recTopics,
            { chap_id: userData.enrolled_Chapters[i].chap_id, details: temp },
          ];
        }
      }
      setMarks({ above60: a, below60: b });
      setTopics(recTopics);
      setSTopics(st);
      setWeakTopics(be);
      setTRT(tot);
      setBG(bag);
    }
  }, [t]);
  const HandleResume = async (chapter) => {
    await updateDoc(doc(db, "user_data", currentUser.uid), {
      current_chap: { chapter },
    });
    setCC(chapter);
    localStorage.setItem("currentChapter", JSON.stringify(chapter));
    navigate("/chapters");
  };

  const handleRecChapNav = async (chap_id, tname) => {
    console.log(chap_id);
    const q = query(chapDataRef, where("chap_id", "==", `${chap_id}`));
    const temp = [];
    const querySnapShot = await getDocs(q);
  
    try {
      querySnapShot.forEach((doc) => {
        temp.push(doc.data());
      });
      console.log(temp);
    } catch (err) {
      console.log(err);
    }
  
    // Check if temp[0] and temp[0].content are defined
    if (temp[0] && Array.isArray(temp[0].content)) {
      let j;
      for (let i = 0; i < temp[0].content.length; i++) {
        if (temp[0].content[i].type === "checkpoint") {
          if (temp[0].content[i].checkPointTopic === tname) {
            j = i;
          }
        }
      }
  
      for (let i = j - 1; i >= 0; i--) {
        if (temp[0].content[i].type === "checkpoint") {
          j = i + 1;
          break;
        } else if (i === 0) {
          j = 0;
        }
      }
  
      let tempo = userData;
      for (let i = 0; i < tempo.enrolled_Chapters.length; i++) {
        if (tempo.enrolled_Chapters[i].chap_id === chap_id) {
          tempo.enrolled_Chapters[i].completion = j;
        }
      }
  
      await updateDoc(doc(db, "user_data", currentUser.uid), {
        enrolled_Chapters: tempo.enrolled_Chapters,
      }).then(async () => {
        await updateDoc(doc(db, "user_data", currentUser.uid), {
          current_chap: { chapter: temp[0] },
        });
        setCC(temp[0]);
        localStorage.setItem("currentChapter", JSON.stringify(temp[0]));
        navigate("/chapters");
      });
    } else {
      console.log('No content found for the chapter.');
    }
  };
  
  const handleUpgrade = async () => {
    const requestData = {
      order_amount: 1999,
      customer_details: {
        customer_id: userData2.id,
        customer_name: userData2.fname,
        customer_email: userData2.email,
        customer_phone: userData2.phone,
      },
    };

    await fetch("https://api.learnwithone.com/api/payment/create-order", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then(async (data) => {
        const orderId = data.order_id;
        const paymentSessionId = data.payment_session_id;
        // Use paymentSessionId as needed
        // console.log("Payment Session ID:", paymentSessionId);
        let checkoutOptions = {
          paymentSessionId: paymentSessionId,
          returnUrl: `https://api.learnwithone.com/api/payment/order-status/${orderId}`,
        };
        await cashfree.checkout(checkoutOptions).then(function (result) {
          if (result.error) {
            alert(result.error.message);
          }
          if (result.redirect) {
            console.log("Redirection");
          }
        });
        // Add your logic here to handle the payment session ID
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const submitResult = async () => {
    setPg(0);
    function calculateWeightedAverageWithCurrent(
      happinessList,
      currentHappiness,
      decayFactor
    ) {
      // If the list is empty, just return the current happiness
      if (happinessList.length === 0) {
        happinessList.push(currentHappiness);
        return currentHappiness;
      }

      // Add current happiness to the list
      happinessList.push(currentHappiness);

      // Ensure the list has at most 10 entries (remove oldest entries if necessary)
      if (happinessList.length > 10) {
        happinessList.shift(); // Remove the oldest entry
      }

      let totalWeight = 0;
      let weightedSum = 0;

      // Calculate weights based on recency using exponential decay
      happinessList.forEach((happiness, index) => {
        const weight = Math.exp(-decayFactor * index); // Exponential decay function
        weightedSum += happiness * weight;
        totalWeight += weight;
      });

      if (totalWeight === 0) {
        return 0; // Return 0 if no entries or weights are provided
      }

      // Calculate the weighted average and scale it to the range 0-5
      const weightedAvg = weightedSum / totalWeight;
      return weightedAvg; // Ensure the result is within the range
    }

    const previousHappinessList =
      userData2.mentalHealth && userData2.mentalHealth.prev
        ? userData2.mentalHealth.prev
        : [];
    // Example list of previous happiness values
    let currentHappiness;

    // Assuming `pg` is defined elsewhere
    if (pos >= 0 && pos < 20) {
      currentHappiness = 1;
    } else if (pos >= 20 && pos < 40) {
      currentHappiness = 2;
    } else if (pos >= 40 && pos < 60) {
      currentHappiness = 3;
    } else if (pos >= 60 && pos < 80) {
      currentHappiness = 4;
    } else if (pos >= 80 && pos <= 100) {
      currentHappiness = 5;
    }
    const decayFactor = 0.001; // Adjust decay factor based on desired rate of decay
    console.log(currentHappiness);
    const weightedAvgWithCurrent = calculateWeightedAverageWithCurrent(
      previousHappinessList,
      currentHappiness,
      decayFactor
    );
    const currentTime = new Date();
    // const fetchAndUpdateDocument = async () => {
    //   try {
    //     const querySnapshot = await getDocs(collection(db, "users"));
    //     querySnapshot.forEach(async (docSnapshot) => {
    //       const docRef = doc(db, "users", currentUser.uid);
    //       const docData = docSnapshot.data();
    //       if (!docData.hasOwnProperty("mentalHealth")) {
    //         // Update the document with the new attribute if it's not present
    //         await updateDoc(docRef, {
    //           mentalHealth: {
    //             mood: weightedAvgWithCurrent,
    //             prev: previousHappinessList,
    //             lastTime: currentTime,
    //             feedBack: feedback,
    //           },
    //         });
    //       } else {
    //         console.log("Attribute already exists in the document.");
    //         await updateDoc(doc(db, "users", currentUser.uid), {
    //           mentalHealth: {
    //             mood: weightedAvgWithCurrent,
    //             prev: previousHappinessList,
    //             lastTime: currentTime,
    //             feedBack: feedback,
    //           },
    //         });
    //       }
    //     });
    //   } catch (error) {
    //     console.error("Error getting documents:", error);
    //   }
    // };
    const fetchAndUpdateDocument = async () => {
      try {
        const docRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const docData = docSnap.data();

          const updatedMentalHealth = {
            mood: weightedAvgWithCurrent,
            prev: previousHappinessList,
            lastTime: currentTime,
            feedBack: feedback,
          };

          // If the "mentalHealth" field exists, update it; otherwise, create it
          await updateDoc(docRef, {
            mentalHealth: docData.mentalHealth
              ? { ...docData.mentalHealth, ...updatedMentalHealth }
              : updatedMentalHealth
          });
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error updating document:", error);
      }
    };

    fetchAndUpdateDocument();
  };

  return (
    <div className="profile">
      {windowWidth >= 768 && <SideBar p={false} />}
      {windowWidth < 768 && <NavBarMob p={false} />}

      {pg > 0 && (
        <div className="howAreYou">
          {pg == 1 && (
            <div className="content">
              <p className="Title">Track your mental health</p>
              <img src={pg1} className="img1"></img>
              <p className="info">
                Have you ever noticed how your body feels when you're happy,
                excited, or maybe a little worried? Our feelings are like secret
                superpowers! By understanding them, we can learn how to manage
                them better and feel good more often
              </p>
              <button
                className="btn"
                onClick={() => {
                  setPg(2);
                }}
              >
                <img src={arr}></img> Log your emotions
              </button>
            </div>
          )}
          {pg == 2 && (
            <div className="content">
              <p className="Title">Track your mental health</p>
              <div className="main">
                <p className="t2">How are you feeling today?</p>
                <div className="m2">
                  <div className="imgs">
                    {pos >= 0 && pos < 20 ? (
                      <img src={s1} alt="Image 1" />
                    ) : (
                      <img src={s1b} alt="Image 2" />
                    )}
                    {pos >= 20 && pos < 40 ? (
                      <img src={s2} alt="Image 1" />
                    ) : (
                      <img src={s2b} alt="Image 2" />
                    )}
                    {pos >= 40 && pos < 60 ? (
                      <img src={s3} alt="Image 1" />
                    ) : (
                      <img src={s3b} alt="Image 2" />
                    )}
                    {pos >= 60 && pos < 80 ? (
                      <img src={s4} alt="Image 1" />
                    ) : (
                      <img src={s4b} alt="Image 2" />
                    )}
                    {pos >= 80 && pos <= 100 ? (
                      <img src={s5} alt="Image 1" />
                    ) : (
                      <img src={s5b} alt="Image 2" />
                    )}
                  </div>
                  <input
                    type="range"
                    onChange={(e) => {
                      setPos(e.target.value);
                    }}
                    min="0"
                    max="100"
                    className="rng"
                  ></input>
                </div>
              </div>

              <button
                className="btn"
                onClick={() => {
                  setPg(3);
                }}
              >
                {" "}
                Next →{" "}
              </button>
            </div>
          )}
          {pg == 3 && (
            <div className="content">
              <p className="Title">Track your mental health</p>
              <div className="why">
                <img src={pg3}></img>
                <p>Tell us Why?</p>
              </div>

              <input
                onChange={(e) => {
                  setFeedBack(e.target.value);
                }}
                type="text"
                className="inpBox"
                placeholder="Write something here.."
              ></input>

              <button
                className="btn"
                onClick={() => {
                  setPg(4);
                }}
              >
                {" "}
                Submit{" "}
              </button>
            </div>
          )}
          {pg == 4 && (
            <div className="content">
              <p className="Title">Track your mental health</p>

              <div className="con">
                <img src={pg4}></img>
                <p className="p1">Submitted!!</p>
                <p className="info">
                  Wishing you all the best, keep rocking it!
                </p>
              </div>

              <button
                className="btn"
                onClick={() => {
                  submitResult();
                }}
              >
                Close Overlay{" "}
              </button>
            </div>
          )}
        </div>
      )}

      {popUp && (
        <div className="analyticsPopUp">
          <div className="content">
            <p className="heading">
              <button
                onClick={() => {
                  setPopUp(false);
                }}
              >
                <img src={arrow3}></img>
              </button>
              <img src={analytics}></img> Analytics
            </p>
            {marks && marks.above60 && (
              <div className="graph">
                <DynamicChart userData={marks} />
                <p>No. of topics that you have scored above and below 60%</p>
              </div>
            )}
            <div className="strengthweakness">
              <div className="points">
                <p className="heading1">Strengths</p>
                <div className="allTps">
                  {strongTopics.map((st, i) => {
                    let topics = st.topics;
                    return (
                      <div className="topic">
                        <div className="div1">
                          <p>
                            {i + 1}. {st.chapName}
                          </p>
                          <button
                            onClick={() => {
                              setSN(`${st.chapName}s`);
                            }}
                          >
                            <img src={arrow2}></img>
                          </button>
                        </div>
                        {sname == `${st.chapName}s` &&
                          topics.map((t) => <p className="ts">⚪ {t}</p>)}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="points">
                <p className="heading1">Weaknesses</p>
                <div className="allTps">
                  {weakTopics.map((st, i) => {
                    let topics = st.topics;
                    return (
                      <div className="topic">
                        <div className="div1">
                          <p>
                            {i + 1}. {st.chapName}
                          </p>
                          <button
                            onClick={() => {
                              setSN(`${st.chapName}w`);
                            }}
                          >
                            <img src={arrow2}></img>
                          </button>
                        </div>
                        {sname == `${st.chapName}w` &&
                          topics.map((t) => <p className="ts">⚪ {t}</p>)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {
            compPopUp && windowWidth < 1024 && 
            <div className='mainP'>
              <div className='contentP'>
                <p className='heading'>
                Desktop Compatibility Only
                </p>
                <img src={popUpImg1}></img>
                <p className='p1'>
                Please note that our games are optimized for desktop play and are not compatible with mobile or touch devices. Kindly access our website on your desktop or laptop to enjoy the full gaming experience.
                </p>
                <p className='p2'>You can still enjoy our animated videos and tackle checkpoint questions on your mobile.</p>
                <button onClick={()=>{setCPU(false)}}>Proceed Anyways</button>
              </div>
            </div>
          } */}
      <div className="profileLeft">
        <div className="userGreetings">
          <div className="text">
            <p className="P1">Hi, {userData2.fname}</p>
            <p className="P2">Let's Learn</p>
          </div>
          {userData2 && userData2.profilePic ? (
            <>
              {userData2.type === 'premium' ? (
                <div className="img">
                  <img src={userData2.profilePic} alt="User profile" className="mainImg"></img>
                  <img src={premuim} alt="Premium user badge" id="badge2"></img>
                </div>
              ) : (
                <div className="img">
                  <img src={userData2.profilePic} alt="User profile" className="mainImg"></img>
                  <img src={free} alt="Free user badge" id="badge"></img>
                </div>
              )}
            </>
          ) : (
            <img src={defaultPic} alt="Default profile icon" />
          )}

        </div>
        <div className="cont1">
          <div className="quickStart">
            <p className="heading">Quick start</p>
            {t && userData && userData.current_chap.chapter ? (
              <div className="chapDiv">
                <div className="info">
                  <p className="name">
                    {userData.current_chap.chapter.chapter_name}
                  </p>
                  <p className="desc">
                    {userData.current_chap.chapter.chapter_desc}
                  </p>
                  <div className="outer">
                    <div className="inner" style={{ width: `${per}%` }}></div>
                  </div>
                </div>
                <img
                  src={btn}
                  onClick={() => HandleResume(userData.current_chap.chapter)}
                ></img>
              </div>
            ) : (
              <p style={{ color: "grey" }}>
                No current chapter data available.
              </p>
            )}
          </div>
          <div className="recommendedChaps">
            <p className="heading" style={{ display: "flex" }}>
              Recommended Topics{" "}
              <p
                style={{
                  backgroundColor: "#B7B58A",
                  marginLeft: "5%",
                  borderRadius: "15px",
                  minHeight: "max-content",
                  padding: '1%',
                  height: 'max-content',
                  marginTop: '-0.6vh'
                }}
              >
                {totalRecTps}
              </p>
            </p>
            <div className="RecChaps">
              {topics.length == 0 && (
                <p style={{ color: "grey" }}>
                  Start chapters to see recommendations!!
                </p>
              )}
              {topics.map((topic) => {
                let t = topic.details;
                {
                  /* let temp = totalRecTps + t.length
              setTRT(temp + t.length) */
                }
                return (
                  <>
                    {windowWidth >= 1024 &&
                      t.map((tc) => (
                        <div className="RecChap" style={{ minWidth: "45%" }}>
                          <p className="chapName" style={{ fontSize: "2vh" }}>
                            Chapter {tc.chapNo}
                          </p>
                          <img
                            src={
                              imgs[Math.floor(Math.random() * (12 - 0 + 1)) + 0]
                            }
                            style={{ fontSize: "2vh" }}
                          ></img>
                          <p
                            className="topicName"
                            style={{ fontSize: "1.5vh" }}
                          >
                            {tc.topic}
                          </p>
                          <button
                            onClick={() => {
                              handleRecChapNav(topic.chap_id, tc.topic);
                            }}
                            className="start"
                          >
                            Start
                          </button>
                        </div>
                      ))}
                    {windowWidth < 1024 &&
                      t.map((tc) => (
                        <div className="RecChap">
                          <p className="chapName">Chapter {tc.chapNo}</p>
                          <img
                            src={
                              imgs[Math.floor(Math.random() * (12 - 0 + 1)) + 0]
                            }
                          ></img>
                          <p className="topicName">{tc.topic}</p>
                          <button
                            onClick={() => {
                              handleRecChapNav(topic.chap_id, tc.topic);
                            }}
                            className="start"
                          >
                            Start
                          </button>
                        </div>
                      ))}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="profileRight">
        <div className="performanceGraph">
          <p className="heading">
            <img src={ChartIcon}></img> Performance Per Chapter
          </p>
          <div className="charts">
            <p className="y-axis">
              <p>0</p> <p>2</p> <p>4</p> <p>6</p> <p>8</p> <p>10</p>
            </p>
            {/* {
              userChaps.map((chap,i)=>{
                let avg = 0;
                if (chap.checkpointResults.length > 0) {
                  for (let j = 0; j < chap.checkpointResults.length; j++) {
                    if (chap.checkpointResults[j].score) {
                      avg = avg + chap.checkpointResults[j].score;
                    }
                  }
                  avg = Math.floor(avg / chap.checkpointResults.length);
                }
                return(
                  <div className='barComponent'>
                    <div className='barOuter'>
                      <div className='barInner' style={{height:`${avg}%`}}></div>
                    </div>
                    <p>CH{i}</p>
                  </div>
                )
              })
            } */}
            {barGraph.map((chap, i) => {
              return (
                <div className="barComponent">
                  <div className="barOuter">
                    <div
                      className="barInner"
                      style={{ height: `${chap}%` }}
                    ></div>
                  </div>
                  <p>CH{i}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="rightBottomSection">
          <div className="mentalHealth">
            <p className="heading">
              <img src={MHicon}></img> Mental Health
            </p>
            {
              windowWidth > 1024 &&
              <div
                className="arc-circle"
                style={{
                  background: `conic-gradient(
                    #F3E61B 0deg ${arcAngle}deg, /* Starting and ending points of the arc */
                    transparent ${arcAngle}deg 360deg, /* Remaining part of the circle */
                    #F3E61B 0deg 0deg
                  )`
                  ,
                  height: '20vh',
                  width: '20vh',
                  justifySelf: 'center'
                }}
              >
                {/* Content or other components */}
                <div className="inner-circle">

                  <div className="inner-circle">
                    {/* <img src={star}></img> */}
                    {userData2 && userData2.mentalHealth && (
                      <p className="score">
                        {Math.floor((userData2.mentalHealth.mood / 5) * 100)}
                      </p>
                    )}
                    <p>of 100</p>
                  </div>
                </div>
              </div>
            }
            {
              windowWidth <= 1024 &&
              <div
                className="arc-circle"
                style={{
                  background: `conic-gradient(
                  #F3E61B 0deg ${arcAngle}deg, /* Starting and ending points of the arc */
                  transparent ${arcAngle}deg 360deg, /* Remaining part of the circle */
                  #F3E61B 0deg 0deg
                )`,
                }}
              >
                {/* Content or other components */}
                <div className="inner-circle">
                  <div className="inner-circle">
                    {/* <img src={star}></img> */}
                    {userData2 && userData2.mentalHealth && (
                      <p className="score">
                        {Math.floor((userData2.mentalHealth.mood / 5) * 100)}
                      </p>
                    )}
                    <p>of 100</p>
                  </div>
                </div>
              </div>
            }
            <p className="p1">Mental Score</p>
          </div>
          {userData2 && userData2.type !== "paid" && (
            <div className="subscribeSection">
              <img src={rocketProfile}></img>
              <div className="gradientDiv">
                <p>
                  You only have access to sample chapters, Subscribe to get
                  unlimited access to all the chapters
                </p>
                <button onClick={handleUpgrade}>Upgrade</button>
              </div>
            </div>
          )}
          {userData2 && userData2.type === "paid" && (
            <div className="analytics">
              <p className="heading">
                <img src={analytics}></img> Analytics{" "}
                <button
                  onClick={() => {
                    setPopUp(true);
                  }}
                >
                  <img src={arrow1}></img>
                </button>
              </p>
              {marks && marks.above60 && <DynamicChart userData={marks} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Profile;