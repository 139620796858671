import React ,{useState}from 'react'
import './why.scss'
import why_card_1 from "../../../../assets/why_card_1.svg"
import why_card_2 from "../../../../assets/why_card_2.svg"
import rocketBg from '../../../../assets/rocket-bg.svg'
import pad from '../../../../assets/Blob-bg.svg'
import { Link, useNavigate } from 'react-router-dom'

function Why() {
    
    const [windowWidth, setWindowWidth] = useState(window.outerWidth);
    const navigate = useNavigate();
    return (
        <div className='why'>
            <div className='whysection' style={{ width: '50vw' }}>
            <img className="rocket-bg" src={rocketBg} alt="rocket-bg" />

                <div className='whycard-title'>
                    <p>Why use</p>
                    <p>One?</p>
                    <button className='Stbtn' onClick={()=>{navigate('/signup')}}><Link className='link' to='/signup'>Start Your Learning Journey</Link></button>
                </div>
            </div>

            <div className="whysection">

                <div className=" card-1">
                    <img className="img-card" src={why_card_1} alt="vr" />
                    <div className='description'>
                    Students have consistently struggled with the monotony of their textbooks, teachers need to be made aware of their student's progress, and parents are concerned about their children's academic standing. One is a one-place
                    solution for this. With this, we also believe in pushing the probability of students engaging with the textbook's contents and getting help from their teachers, parents, and school to <span style={{backgroundColor:'#D9D9D9',borderRadius:'15px',paddingLeft:'5px',paddingRight:'5px'}}>One</span>.
                    </div>

                </div>
            </div>
            <div className="whysection">
                <div className=" card-2">
                <div className='description'>
                        With the mission to create the Most <span style={{backgroundColor:'#D9D9D9',borderRadius:'3vh',paddingLeft:'15px',paddingRight:'15px'}}>Innovative</span> & <span style={{backgroundColor:'#D9D9D9',borderRadius:'3vh',paddingLeft:'15px',paddingRight:'15px'}}>Engaging</span> Education technology
                    </div>
                    <img  className="img-card" src={why_card_2} alt="vr" />
                </div>
            </div>
            <div className="whysection">
                <div className=" card-3">
                    {
                        windowWidth > 768 && 
                        <img className='padL' src={pad}></img>
                    }
                    <div className="description">
                    Our vision is to help build a society wherein the definition of your success <span style={{backgroundColor:'#fced217d',borderRadius:'3vh',paddingLeft:'15px',paddingRight:'15px'}}>need not</span> resonate with that of the society.
                    </div>
                    {
                        windowWidth < 768 && 
                        <img className='padL' src={pad}></img>
                    }
                </div>

            </div>


        </div>
    )
}

export default Why