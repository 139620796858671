import { Authcontext } from '../../../contextProvider'
import SideBar from '../SideBar/SideBar'
import './T&PDashboard.scss'


import objects from '../../../assets/OBJECTS.svg'
import btn from '../../../assets/profileResumeBtn.png'
import ChartIcon from '../../../assets/chartIcon.svg'
import rocketProfile from '../../../assets/rocketProfile.png'
import MHicon from '../../../assets/mentalHealthIcon.png'
import analytics from '../../../assets/ion_analytics-outline.svg'
import NavBarMob from '../SideBar/NavBarMob'
import DynamicChart from './DynamicChart'
import defaultPic from '../../../assets/defaultPic.svg'
import arrow2 from '../../../assets/Vector2.svg'
import arrow1 from '../../../assets/Vector(2).svg'
import arrow3 from '../../../assets/Vector(1).svg'
import star from '../../../assets/_.svg'
import one from '../../../assets/1.png'
import two from '../../../assets/2.png'
import three from '../../../assets/3.png'
import four from '../../../assets/4.png'
import five from '../../../assets/5.png'
import six from '../../../assets/6.png'
import seven from '../../../assets/7.png'
import eight from '../../../assets/8.png'
import nine from '../../../assets/9.png'
import ten from '../../../assets/10.png'
import eleven from '../../../assets/11.png'
import twelve from '../../../assets/12.png'
import React, { useContext, useEffect, useState } from 'react'
import { collection, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { getDocs, doc } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { useNavigate } from 'react-router-dom'
import { auth } from '../../../services/firebase'
import { onAuthStateChanged ,sendEmailVerification,signOut } from 'firebase/auth'


function TeacherDashboard() {
    const userDataRef = collection(db, 'educators');
    const userDataRef1 = collection(db, 'chapters');
    const userDataRef2 = collection(db, 'user_data');
    const userDataRef3 = collection(db,'users')
    const [userData,setUD] = useState();
    const [classes,setClasses] = useState([]);
    const {currentUser} = useContext(Authcontext)
    const [windowWidth, setWindowWidth] = useState(window.outerWidth);
    const [currentClass,setCC] = useState('');
    const [currentSection,setCS] = useState('');
    const [currentSelectedSection,setCSS] = useState('')
    const [currentSelectedClass,setCSC] = useState('')
    const [chapters,setChapters] = useState([]);
    const [currentChap,setCChap] = useState('chap_0');
    const [currentChapTopics,setCCT] = useState([]);
    const [students,setStudents] = useState([]);
    const [graph,setGraph] = useState([]);
    const [lessThan,setLT] = useState([]);
    const [moreThan,setMT] = useState([]);
    const [studentList,setSL] = useState([]);
    const [listView,setLV] = useState(false);
    useEffect(()=>{
      const fetchUserInfo =async()=>{
        const q1 = query(userDataRef,where('id','==',`${currentUser.uid}`))
        let temp1 = []
        const querySnapShot1 = await getDocs(q1)
        try {
            querySnapShot1.forEach((doc) => {
                temp1.push(doc.data())
            })
        } catch (err) {
            console.log(err)
        }

        setUD(temp1[0])
        if(temp1[0]){
          setClasses(temp1[0].classes)
        }
      }
      fetchUserInfo()
      const fetchChapInfo =async()=>{
        const q1 = query(userDataRef1,orderBy('chap_id'))
        let temp1 = []
        const querySnapShot1 = await getDocs(q1)
        try {
            querySnapShot1.forEach((doc) => {
                temp1.push(doc.data())
            })
        } catch (err) {
            console.log(err)
        }
        temp1.sort((a, b) => {
          const numberA = parseInt(a.chap_id.match(/\d+/)[0], 10);
          const numberB = parseInt(b.chap_id.match(/\d+/)[0], 10);
    
          return numberA - numberB; // Compare numeric values
        });
        setChapters(temp1)
        console.log(temp1[0]) 
      }
      fetchChapInfo();
    },[])

    const viewDetails = async () =>{
      if(currentSelectedSection != '' && currentChap != ''){
        console.log(`${userData.school.substring(0,3)}${currentClass}${currentSelectedSection.substring(0,3)}${userData.name.substring(0,3)}`);
        console.log(currentClass)
        const q1 = query(userDataRef2,where('referalID','==',`${userData.school.substring(0,3)}${currentClass}${currentSelectedSection.substring(0,3)}${userData.name.substring(0,3)}`))
        let temp1 = []
        const querySnapShot1 = await getDocs(q1)
        try {
            querySnapShot1.forEach((doc) => {
                temp1.push(doc.data())
            })
        } catch (err) {
            console.log(err)
        }
  
        setStudents(temp1)
        console.log(temp1)
      }
      else{
        alert('Choose a class and a chapter')
      }
    }

    useEffect(()=>{
      if(currentChap != ''){
        const chapNo = currentChap.match(/\d+/);
        let integerValue = 0; 
        if (chapNo) {
            integerValue = parseInt(chapNo[0], 10);
            console.log(integerValue);
        } else {
            console.log("No numeric part found in the string.");
        }
        let noOfTests = 0;
        console.log(chapters[integerValue])
        if(students.length > 0){
          let topics = [];
          for(let i=0;i<chapters[integerValue].content.length;i++){
            if(chapters[integerValue].content[i].type == 'checkpoint'){
              topics = [...topics,{tNo:`T${noOfTests + 1}`,topicName:chapters[integerValue].content[i].checkPointTopic}]
              console.log(topics)
              noOfTests++;
            }
            setCCT(topics)
          }
          console.log(noOfTests)

          const sumOf = Array.from({ length: noOfTests }, () => 0);
          const totalStudents = Array.from({ length: noOfTests }, () => 0);
          for(let i=0;i<students.length;i++){
            let stChaps = [...students[i].enrolled_Chapters]
            console.log(stChaps)
            for(let j=0;j<stChaps.length;j++){
              if(stChaps[j].chap_id == currentChap){
                for(let k=0;k<stChaps[j].checkpointResults.length;k++){
                  sumOf[k] = sumOf[k] + stChaps[j].checkpointResults[k].score
                  totalStudents[k] = totalStudents[k] + 1;
                }
                break;
              }
            }
          }

          console.log(sumOf)
          console.log(totalStudents)

          for(let i=0;i<sumOf.length;i++){
            if(totalStudents[i] != 0){
              sumOf[i] = sumOf[i]/totalStudents[i];
            }
          }

          console.log(sumOf);
          setGraph(sumOf);
        }
      }
    },[students])

    useEffect(()=>{
      let l = [];
      let m = [];
      if(graph.length > 0){
        for(let i=0;i<graph.length;i++){
          if(graph[i] >= 50){
            m = [...m,currentChapTopics[i]]
          }
          else{
            l = [...l,currentChapTopics[i]]
          }
        }

        setLT(l);
        setMT(m);
      }
    },[graph])

    const setClassSections = (classItem) =>{
      console.log(classItem)
      setCS(classItem.sections)
      setCSC(classItem)
      setCC(classItem.class)
    }

    const viewStudents = async () =>{
      if(currentSelectedSection != '' && currentClass){
        console.log(`${userData.school.substring(0,3)}${currentClass}${currentSelectedSection.substring(0,3)}${userData.name.substring(0,3)}`);
        console.log(currentClass)
        const q1 = query(userDataRef2,where('referalID','==',`${userData.school.substring(0,3)}${currentClass}${currentSelectedSection.substring(0,3)}${userData.name.substring(0,3)}`))
        let temp1 = []
        const querySnapShot1 = await getDocs(q1)
        try {
            querySnapShot1.forEach((doc) => {
                temp1.push(doc.data())
            })
        } catch (err) {
            console.log(err)
        }
        let data = [];
        for(let i=0;i<temp1.length;i++){
          const q1 = query(userDataRef3,where('id','==',`${temp1[i].id}`))
          let temp2 = []
          const querySnapShot1 = await getDocs(q1)
          try {
              querySnapShot1.forEach((doc) => {
                  temp2.push(doc.data())
              })
          } catch (err) {
              console.log(err)
          }
          console.log(temp2)
          data = [...data,{name:temp2[0].fname,class:temp2[0].currentClass,email:temp2[0].email}]
        }
        setSL(data)
        console.log(data)
        setLV(true);
        // setStudents(temp1)
        // console.log(temp1)
      }
      else{
        alert('Choose a class and a chapter')
      }
    }
  return (
    <div className='profile'>
          {
            listView && 
            <div className='ListView' onClick={()=>{setLV(false)}}>
              <div className='ListViewContent'>
                <div className='student'>
                  <p>NO</p>
                  <p>Name</p>
                  <p>Class</p>
                  <p>Email</p>
                </div>
                <div className='studentList'>
                  {
                    studentList.map((student,i)=>(
                      <div className='student'>
                        <p>{i+1}</p>
                        <p>{student.name}</p>
                        <p>{student.class}</p>
                        <p>{student.email}</p>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          }
          {
            windowWidth >= 768 &&
            <SideBar p = {true}/>
          }
          {
            windowWidth < 768 && 
            <NavBarMob p = {true}/>
          }
      {
        userData && userData.classes &&
        <div className='profileLeft'>
          <div className='userGreetings'>
            <div className='text'>
              <p className='P1'>Hi, {userData.name}</p>
              <p className='P2'>Let's Teach</p>
            </div>
            <img src={defaultPic} alt='Default profile icon' />
          </div>
          <div className='cont1'>
          <div className='classSelect'>
            <p className='heading'>You class Performance</p>
            <p className='heading2'>Select Grade</p>
            <div className='grades'>
            {
              classes.map((classItem) => {
                if(currentSelectedClass != classItem){
                  return(
                    <div className='class' onClick={()=>{setClassSections(classItem)}} style={{backgroundColor:'#2E2E2E',color:'white'}}>Grade {classItem.class}</div>
                  )
                }
                else{
                  return(
                    <div className='class' style={{backgroundColor:'#F3E61B',color:'black'}}>Grade {classItem.class}</div>
                  )
                }
              })
            }
            </div>
          </div>
          <div className='sections'>
            <p className='heading2'>Select Section</p>
            <div className='sectionSelect'>
              {
                currentSection == '' && 
                <p className='alert'>Please Select a Grade</p>
              }
              {
                currentSection != '' && 
                currentSection.map((section)=>{
                  if(section == currentSelectedSection){
                    return (
                      <div className='section' onClick={()=>{setCSS(section)}} style={{backgroundColor:'#F3E61B',color:'black'}}>{section}</div>
                    )
                  }
                  else{
                    return(
                      <div className='section' onClick={()=>{setCSS(section)}} style={{backgroundColor:'#2E2E2E',color:'white'}}>{section}</div>
                    )
                  }
                })
              }
            </div>
          </div>
          <div className='chapterSelect'>
            <div className='selectDiv'>
              <p className='heading2'>Chapters</p>
              <select onChange={(e) => { setCChap(`chap_${e.target.value}`) }}>
                {
                  chapters.map((chapter,i)=>(
                    <option className='chapter' key={i} value={i}>Chapter {i}</option>
                  ))
                }
              </select>
            </div>
            <button className='chapterView' onClick={()=>{viewDetails()}}>View</button>
            <button className='chapterView' onClick={()=>{viewStudents()}}>View Students</button>
          </div>
          </div>
        </div>
      }

      <div className='profileRight'>
        <div className='performanceGraph'>
          <p className='heading'><img src={ChartIcon}></img> Performance Per Chapter</p>
          <div className='charts'>
            <p className='y-axis'><p>0</p> <p>20</p> <p>40</p> <p>60</p> <p>80</p> <p>100</p></p>
            {
              graph.map((val,i)=>{
                if(val >= 50){
                    return(
                    <div className='barComponent'>
                      <div className='barOuter'>
                        <div className='barInner' style={{height:`${val}%`}}></div>
                      </div>
                      <p>T{i+1}</p>
                    </div>
                  )
                }
                else{
                  return(
                    <div className='barComponent'>
                      <div className='barOuter'>
                        <div className='barInner' style={{height:`${val}%`,backgroundColor:'red'}}></div>
                      </div>
                      <p>T{i+1}</p>
                    </div>
                  )
                }
              })
            }

          </div>
        </div>


        <div className='rightBottomSection'>

        <div className='mentalHealth'>
        <p className='heading'>Topics Names</p>
        <div className='topicsContent'>
          {
            moreThan.map((i)=>(
              <div className='topic'>
                <p className='tNo'>{i.tNo}</p>
                <p className='topicName'>{i.topicName}</p>
              </div>
            ))
          }
        </div>
        </div>
          {/* <div className='subscribeSection'>
              <img src={rocketProfile}></img>
              <div className='gradientDiv'>
                <p>You only have access to sample chapters, Subscribe to get unlimited access to all the chapters</p>
                <button>Upgrade</button>
              </div>
          </div> */}
          <div className='analytics'>
          <p className='heading'>Topics Below 50%</p>
          <div className='topicsContent'>
            {
              lessThan.map((i)=>(
                <div className='topic'>
                  <p className='tNo'>{i.tNo}</p>
                  <p className='topicName'>{i.topicName}</p>
                </div>
              ))
            }
          </div>

          </div>
        </div>

      </div>
    </div>
  );
}

export default TeacherDashboard;
