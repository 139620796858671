import './navbar.scss';
import logo from '../../logo.svg';
import NBLogo_1 from '../../assets/nb_features.svg';
import NBLogo_2 from '../../assets/nb_why.svg';
import NBLogo_3 from '../../assets/nb_pricing.svg';
import NBLogo_4 from '../../assets/nb_contact.svg';
import NBLogo_5 from '../../assets/books-book-svgrepo-com.svg';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Preload from 'react-preload';

function Navbar({ features, whyone, pricing, contactUs }) {
  let navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.outerWidth);
  const [vis, setVis] = useState('hidden')
  const [color1, setColor1] = useState('#FCEE21')
  const [color2, setColor2] = useState('#FCEE21')
  const [color3, setColor3] = useState('#FCEE21')
  const [color4, setColor4] = useState('#FCEE21')
  const [color5, setColor5] = useState('#FCEE21')
  const [ElementNum, setEN] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.outerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLinkHover = (c1, num) => {
    if (num == 1) {
      setColor1(`${c1}`);
    }
    else if (num == 2) {
      setColor2(`${c1}`);
    }
    else if (num == 3) {
      setColor3(`${c1}`);
    }
    else if (num == 4) {
      setColor4(`${c1}`);
    }
    else {
      setColor5(`${c1}`);
    }
    setEN(num)
  };

  const handleLinkLeave = () => {
    setEN(0)
    setColor1('#FCEE21')
    setColor2('#FCEE21')
    setColor3('#FCEE21')
    setColor4('#FCEE21')
    setColor5('#FCEE21')
  };



  const scrollToComponent = (ref) => {
    if (ref && ref.current && windowWidth > 1024 && ref == whyone) {
      window.scrollTo({
        top: 900,
        behavior: "smooth",
      });
      console.log(ref.current.offsetTop, "hi")
    }
    else if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth",
      });
    }
    setVis('hidden')
  };
  const NavVis = () => {
    if (vis == 'hidden') {
      setVis('visible')
    }
    else {
      setVis('hidden')
    }
  }

  const imageSrc =
    activeLink === 'features'
      ? 'features-image.jpg'
      : activeLink === 'why-us'
        ? 'why-us-image.jpg'
        : activeLink === 'pricing'
          ? 'pricing-image.jpg'
          : activeLink === 'contact-us'
            ? 'contact-us-image.jpg'
            : 'default-image.jpg';

  return (
    <>

      {
        (windowWidth <= 820) ?
          <div className="navbar">
            <Link className="logo-container" to="/">
              <img className="logo" src={logo} alt="one logo" />
            </Link>
            {
              windowWidth <= 820 && windowWidth > 700 &&
              <ul className="register-buttons">
                <Link to="/login" className="login">
                  LOGIN
                </Link>
                <Link to="/signup" className="signup">
                  SIGN UP
                </Link>
              </ul>
            }
            <button className='more' onClick={() => { NavVis() }}>☰</button>
            <Preload images={[NBLogo_1, NBLogo_2, NBLogo_3, NBLogo_4]}>
              <div className='popupNavbar' style={{ visibility: `${vis}` }}>
                <ul className="nav-links">
                  <li>
                    <div
                      onMouseEnter={() => handleLinkHover('features')}
                      onMouseLeave={handleLinkLeave}
                      className="link"
                      onClick={() => { scrollToComponent(features) }}

                    >
                      FEATURES
                    </div>
                  </li>
                  <li>
                    <div
                      onMouseEnter={() => handleLinkHover('why-us')}
                      onMouseLeave={handleLinkLeave}
                      className="link"
                      onClick={() => { scrollToComponent(whyone) }}
                    >
                      WHY ONE
                    </div>
                  </li>
                  <li>
                    <div
                      onMouseEnter={() => handleLinkHover('pricing')}
                      onMouseLeave={handleLinkLeave}
                      className="link"
                      onClick={() => { scrollToComponent(pricing) }}
                    >
                      PRICING
                    </div>
                  </li>
                  <li>
                    <div
                      onMouseEnter={() => handleLinkHover('contact-us')}
                      onMouseLeave={handleLinkLeave}
                      className="link"
                      onClick={() => { scrollToComponent(contactUs) }}
                    >
                      CONTACT US
                    </div>
                  </li>
                  <li>
                    <div
                      onMouseEnter={() => handleLinkHover('blog')}
                      onMouseLeave={handleLinkLeave}
                      className="link"
                      onClick={() => { navigate("/blogs") }}
                    >
                      BLOG
                    </div>
                  </li>
                  {windowWidth < 768 &&
                    <li>
                      <ul className="register-btns">
                        <Link to="/login" className="login">
                          LOGIN
                        </Link>
                        <Link to="/signup" className="signup">
                          SIGN UP
                        </Link>
                      </ul>
                    </li>
                  }

                  {/* <li className='active'>
                  {activeLink && (
                    <div className="image-wrapper">
                      <img src={imageSrc} alt="Image" className="image" />
                    </div>
                  )}
                </li> */}

                </ul>
              </div>

            </Preload>

            {/* <button onClick={()=>{setVis('visible')}} className='nav-btn'>heyy</button> */}
          </div>

          : <div className="navbar">
            <Link className="logo-container" to="/">
              <img className="logo" src={logo} alt="one logo" />
            </Link>
            <Preload images={[NBLogo_1, NBLogo_2, NBLogo_3, NBLogo_4]}>
              <ul className="nav-links">
                <li>
                  <div
                    onMouseEnter={() => handleLinkHover('#713FFF', 1)}
                    onMouseLeave={handleLinkLeave}
                    className="link"
                    onClick={() => { scrollToComponent(features) }}
                    style={{ color: `${color1}` }}
                  >
                    FEATURES
                    {
                      ElementNum == 1 &&
                      <img src={NBLogo_1}></img>
                    }
                  </div>
                </li>
                <li>
                  <div
                    onMouseEnter={() => handleLinkHover('#FFFFFF', 2)}
                    onMouseLeave={handleLinkLeave}
                    className="link"
                    onClick={() => { scrollToComponent(whyone) }}
                    style={{ color: `${color2}` }}
                  >
                    WHY ONE
                    {
                      ElementNum == 2 &&
                      <img src={NBLogo_2}></img>
                    }
                  </div>
                </li>
                <li>
                  <div
                    onMouseEnter={() => handleLinkHover('#56CD62', 3)}
                    onMouseLeave={handleLinkLeave}
                    className="link"
                    onClick={() => { scrollToComponent(pricing) }}
                    style={{ color: `${color3}` }}
                  >
                    PRICING
                    {
                      ElementNum == 3 &&
                      <img src={NBLogo_3}></img>
                    }
                  </div>
                </li>
                <li>
                  <div
                    onMouseEnter={() => handleLinkHover('#CD6FC9', 4)}
                    onMouseLeave={handleLinkLeave}
                    className="link"
                    onClick={() => { scrollToComponent(contactUs) }}
                    style={{ color: `${color4}` }}
                  >
                    CONTACT US
                    {
                      ElementNum == 4 &&
                      <img src={NBLogo_4}></img>
                    }
                  </div>
                </li>
                <li>
                  <div
                    onMouseEnter={() => handleLinkHover('#81cdc6', 5)}
                    onMouseLeave={handleLinkLeave}
                    className="link"
                    onClick={() => {
                      navigate('/blogs');
                    }}
                    style={{ color: `${color5}` }}
                  >
                    BLOGS
                    {
                      ElementNum == 5 &&
                      <img src={NBLogo_5}></img>
                    }
                  </div>
                </li>

                {/* <li className='active'>
                {activeLink && (
                  <div className="image-wrapper">
                    <img src={imageSrc} alt="Image" className="image" />
                  </div>
                )}
              </li> */}

              </ul>
            </Preload>
            <ul className="register-buttons">
              <Link to="/login" className="login">
                LOGIN
              </Link>
              <Link to="/signup" className="signup">
                SIGN UP
              </Link>
            </ul>

          </div>}
    </>
  );
}

export default Navbar;
