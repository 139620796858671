import React, { useState, useEffect } from 'react'
import "./reports.scss"
import { Link, useNavigate } from 'react-router-dom'
import reportsBar from '../../../../assets/reports-bar.svg'
import experienceButton from '../../../../assets/experience-button.svg'
import experienceButton1 from '../../../../assets/experience-button1.png'
import digitalTextIcon from '../../../../assets/digital-textbook.svg'

function Reports() {
  const [windowWidth, setWindowWidth] = useState(window.outerWidth);
  const navigate = useNavigate()

  const [currentIndex, setCurrentIndex] = useState(0);
  const items = [
    { img: './reports/whatsapp.jpg', text: 'Image 1' },
    { img: './reports/card_1.jpg', text: 'Image 2' },
    { img: './reports/card_2.jpg', text: 'Image 3' },
    { img: './reports/card_3.jpg', text: 'Image 4' },
    { img: './reports/card_4.jpg', text: 'Image 5' },
    { img: './reports/card_5.jpg', text: 'Image 6' }
  ]; // Replace with actual image paths and text

  // Automatically move the slider every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 3000); // Change every 3 seconds

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [items.length]);

  return (
    <div className='reports'>
      <div className='left-content'>
        <div className='card'>
          <div className='title'>Reports are created using our advanced ML <i>algorithms</i>.</div>
          <div className='row'>
            <img src={reportsBar} className="reports-bar" alt="ab" />
            <div className='column'>
              <p className='subtitle'>Statistics created per chapter for the entire class & mailed to respective teachers.</p>
              <p className='subtitle2'>This tool facilitates communication with teachers regarding the overall direction of the class.</p>
              <p className='subtitle1'>Statistics created per chapter for students and parents.</p>
              <p className='subtitle2'>This tool serves as an excellent way to assess a student's strengths and weaknesses, while also providing insight into the challenging areas where they may encounter difficulties.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='right-content'>
        <div className="circular-slider">
          {items.map((item, index) => {
            // Calculate the position of the item relative to the currentIndex
            const position = (index - currentIndex + items.length) % items.length;

            let className = 'slider-item';
            if (position === 0) className += ' far-left';  // Faded and smaller, far left
            if (position === 1) className += ' left';      // Left-hand side
            if (position === 2) className += ' middle';    // Middle (bigger) one
            if (position === 3) className += ' right';     // Right-hand side
            if (position === 4) className += ' far-right'; // Faded and smaller, far right
            if (position === 5) className += ' behind';    // Further back

            return (
              <div key={index} className={className}>
                <img src={item.img} alt={item.text} className="slider-image" />
                <p className="slider-text">{item.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  )
}

export default Reports