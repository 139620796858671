import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import EducatorLogin from "./pages/Login/T&PLogin";
import Signup from "./pages/Signup/Signup";
import Homepage from "./pages/Homepage/Homepage";
import NotFound from "./components/NotFound/NotFound";
import Quiz from "./pages/Dashboard/quiz/quiz";
import { useContext } from "react";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/300.css";
import "@fontsource/manrope/500.css";
import "@fontsource/manrope/400.css";
import "@fontsource/manrope/300.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/300.css";
import AccountSetup from "./pages/AccountSetup/AccountSetup";
import Dashboard from "./pages/Dashboard/Dashboard";
import Profile from "./pages/Dashboard/Profile/Profile";
import Library from "./pages/Dashboard/Library/Library";
import Chapters from "./pages/Dashboard/chapters/chapters";
import { Authcontext } from "./contextProvider";
import { Navigate } from 'react-router-dom';
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfServices from "./pages/TermOfService/TermOfService";
import TeacherDashboard from "./pages/Dashboard/Profile/T&PDashboard";
import PrincipleDashboard from "./pages/Dashboard/Profile/PDashboard";
import BlogList from "./pages/Blogs/BlogList";


export default function App() {
  const {currentUser} = useContext(Authcontext)
  const {currentChapter,setCC} = useContext(Authcontext)

  const ProtectedRoute = ({children})=>{
    if(!currentUser){
      return(
        <Navigate to="/login"/>
      ) 
    }
    return(
      children
    )
  }
  const ProtectedRoute2 = ({children})=>{
    if(!currentChapter){
      return(
        <Navigate to="/library"/>
      ) 
    }
    return(
      children
    )
  }


  return (
    <div className="App">  
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/login" element={<Login />} />
          <Route path='/educator' element={<EducatorLogin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path='/test' element={<Quiz />}/>
          <Route path="/accountSetup" element={<AccountSetup/>} />
          <Route path="/dashboard" element={<ProtectedRoute><Profile/></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/library" element={<ProtectedRoute><Library /></ProtectedRoute>} />
          <Route path="/Teacher-Dashboard" element={<ProtectedRoute><TeacherDashboard /></ProtectedRoute>} />
          <Route path="/Principal-Dashboard" element={<ProtectedRoute><PrincipleDashboard /></ProtectedRoute>} />
          <Route path="/chapters" element={<ProtectedRoute><ProtectedRoute2><Chapters /></ProtectedRoute2></ProtectedRoute>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/terms-of-service" element={<TermsOfServices/>} />
          <Route path="/blogs" element={<BlogList/>} />

          <Route path='*' element={<NotFound/>}></Route>
        </Routes>
      </BrowserRouter>   
    </div>
  );
}
