import React, { useContext, useEffect, useState, useRef } from 'react';
import './chapters.scss';
import { Authcontext } from '../../../contextProvider';
import YouTube from 'react-youtube';
import { collection, orderBy, query, updateDoc, where } from 'firebase/firestore';
import headerImg from '../../../assets/checkpoint_header_img.png'
import { getDocs, doc } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import ProgressBar from '../progressBar/progressBar';
import SideBar from '../SideBar/SideBar';
import NavBarMob from '../SideBar/NavBarMob';
import { useNavigate } from 'react-router-dom';

function Chapters() {
  const { currentUser } = useContext(Authcontext);
  const { currentChapter, setCC } = useContext(Authcontext);
  const [ch,setCh] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.outerWidth);
  const [windowHeight, setWindowHeight] = useState(window.outerHeight);
  const [loaded,setLoad] = useState(false)
  const [i, setI] = useState(0);
  const [userData, setUC] = useState([]);
  const [score, setScore] = useState();
  const [content, setContent] = useState();
  const playerRef = useRef(null);
  const [view,setView] = useState('hidden')
  const navigate = useNavigate()

  const [answers, setAnswers] = useState([]);
  const [showAns, setShow] = useState(false);
  const [quizStatus,setQS] = useState(false)

  const userDataRef = collection(db, 'user_data');
  const chaptersRef = collection(db, "chapters");
  const [fullSc,setFullSc] = useState(false)


  useEffect(()=>{

    const fetchChapters = async()=>{
      const q = query(chaptersRef,orderBy('no'))
      const temp = []
      const querySnapShot = await getDocs(q)
      try {
          querySnapShot.forEach((doc) => {
              temp.push(doc.data())
          })
          setCh(temp)
          console.log(temp)
      } catch (err) {
          console.log(err)
      }
    }
    fetchChapters()
  },[])
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // Required for Chrome
    };

    const handleReload = () => {
      if (performance.navigation.type === 1) {
        navigate('/new-route'); // Replace '/new-route' with the desired route
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleReload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleReload);
    };
  }, [navigate]);
  const opts = {
    width: '100%',
    height:'400px',
    playerVars: {
      rel: 0,
      autoplay: 1,     
      removeCopyLink:true,
      removeAccountInfo:true
    },
  };
  const opts2 = {
    width: `${window.innerWidth}px`,
    height:`${window.innerHeight}px`,
    playerVars: {
      rel: 0,
      autoplay: 1
      // fullscreen:0
    },
  };
  const handleFullScreen = () => {
    const frameElement = document.querySelector('.frame');

    if (frameElement) {
      if (frameElement.requestFullscreen) {
        frameElement.requestFullscreen();
      } else if (frameElement.mozRequestFullScreen) {
        frameElement.mozRequestFullScreen();
      } else if (frameElement.webkitRequestFullscreen) {
        frameElement.webkitRequestFullscreen();
      } else if (frameElement.msRequestFullscreen) {
        frameElement.msRequestFullscreen();
      }
    }
    setFullSc(true)
  };

  function exitFullScreenHandler() {
    setFullSc(false)
  }
  
  // Add event listener for exiting full screen
  document.addEventListener("fullscreenchange", function () {
    if (!document.fullscreenElement) {
      exitFullScreenHandler();
    }
  });
  
  // Add event listener for exiting full screen in Mozilla Firefox
  document.addEventListener("mozfullscreenchange", function () {
    if (!document.mozFullScreenElement) {
      exitFullScreenHandler();
    }
  });
  
  // Add event listener for exiting full screen in Safari
  document.addEventListener("webkitfullscreenchange", function () {
    if (!document.webkitFullscreenElement) {
      exitFullScreenHandler();
    }
  });
  
  // Add event listener for exiting full screen in Microsoft Edge and IE
  document.addEventListener("msfullscreenchange", function () {
    if (!document.msFullscreenElement) {
      // Full screen mode has been exited
      exitFullScreenHandler();
    }
  });


  const handleVideoEnd = async (event) => {
    if (i + 1 < currentChapter.content.length) {
      setI(i + 1);
    }
     else if (i === currentChapter.content.length - 1) {
      let temp = userData;
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].chap_id === `${currentChapter.chap_id}`) {
          temp[i].status = 2;
        }
      }
      let c = 0
      for(let i = 0;i<temp.length;i++){
        if(temp[i].status == 2){
          c++;
        }
      }
      if(temp.length > 0){
        await updateDoc(doc(db, 'user_data', currentUser.uid), {
          enrolled_Chapters: temp,
          completion:c
        });
      }
    }
  };
  const HandleNext = async() => {
    setQS(false)
    if (i + 1 < currentChapter.content.length) {
      setI(i + 1);
      setShow(false)
    }
    else if(userData.length > 0 &&  i+1 === currentChapter.content.length){

        let temp = userData;
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].chap_id === `${currentChapter.chap_id}`) {
            temp[i].completion = 100
            temp[i].status = 2;
          }
        }
        let c = 0
        for(let i = 0;i<temp.length;i++){
          if(temp[i].status == 2){
            c++;
          }
        }
        
        if(temp.length > 0){
          await updateDoc(doc(db, 'user_data', currentUser.uid), {
            enrolled_Chapters: temp,
            completion:c
          })      
            .then(()=>{
              navigate('/library')
            })
        }
        
      }
        setAnswers([]);
    };
  
  const HandlePrev = () => {
    if (i - 1 >= 0) {
      setI(i - 1);
    }
    setQS(false)
  };

  const handleOptionSelect = (questionIndex, optionIndex) => {
    if (!showAns) {
      const updatedAnswers = [...answers];
      updatedAnswers[questionIndex] = optionIndex;
      console.log()
      setAnswers(updatedAnswers);
    }

    console.log(answers)

    if (answers.filter(answer => answer !== undefined).length == content.ques.length - 1) {
      setQS(true);
    }

  };

  const calculateScore = () => {
    let score = 0;
    content.ques.forEach((question, index) => {
      if (typeof question.answer === "string" && answers[index] != null) {
        let k = 0;
        if (answers[index].length === question.answer.length) {
          for (let i = 0; i < answers[index].length; i++) {
            if (answers[index][i] != question.answer[i]) {
              break;
            }
            else{
              k++;
            }
          }
          if (k === question.answer.length) {
            score++;
          }
        }
      } else if (answers[index] === question.answer && typeof question.answer === "number") {
        score++;
      }
    });
    return score;
  };
  
  useEffect(()=>{
    if(userData.length!=0){
      let temp = userData
      for(let i=0;i<temp.length;i++){
          if(temp[i].chap_id == `${currentChapter.chap_id}`){
              setI(temp[i].completion)
          }
      }
  }
  },[userData])

  const handleSubmit = async() => {
    console.log(answers)
    let score = calculateScore()
    setScore(calculateScore());
    setShow(true);
    const temp = [...userData]; // Create a copy of userData to avoid modifying the original array

    // Find the index of the userData that matches the current chapter
    const index = temp.findIndex(user => user.chap_id === currentChapter.chap_id);
    
    if (index !== -1) {
      const checkpointResults = temp[index].checkpointResults || [];
    
      // Ensure that checkpointResults has enough elements
      while (checkpointResults.length <= content.checkpointId) {
        checkpointResults.push({});
      }
    
      // Update the specific checkpoint result
      if (!isNaN(score)) {
        // Update the specific checkpoint result
        checkpointResults[content.checkpointId] = {
          score: (score / answers.length) * 100,
          topic: `${content.checkPointTopic}`,
        };
      }
      else{
        checkpointResults[content.checkpointId] = {
          score: 0,
          topic: `${content.checkPointTopic}`,
        };  
      }
      // Update the checkpointResults in the userData copy
      temp[index].checkpointResults = checkpointResults;
    }
    
    
    if(temp.length > 0){
      await updateDoc(doc(db, 'user_data', currentUser.uid), {
        enrolled_Chapters: temp,
      });
    }
  };
  const HandleMatchSave = (e,r,questionIndex) =>{
    e.preventDefault();
    let ans = ''
    for(let i=0;i<r;i++){
      ans = ans + `${e.target[i].value}`;
    }

    if (!showAns && ans.length>1) {
      const updatedAnswers = [...answers];
      updatedAnswers[questionIndex] = ans;
      setAnswers(updatedAnswers);
      console.log(updatedAnswers)
    }

    console.log(answers)

    if (answers.filter(answer => answer !== undefined).length == content.ques.length - 1) {
      setQS(true);
    }

  }
  const HandleTickOptionClick = (questionIndex, optionIndex) => {
    if (!showAns) {
      setAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        const currentValue = updatedAnswers[questionIndex] || '';
        const str = optionIndex.toString();
  
        if (!currentValue.includes(str)) {
          updatedAnswers[questionIndex] = currentValue + str;
        }
        else{
          updatedAnswers[questionIndex] = currentValue.replace(new RegExp(str,"g"),"")
          console.log(updatedAnswers[questionIndex])
        }
        console.log(updatedAnswers)
        return updatedAnswers;
      });
  

      if (answers.filter(answer => answer !== undefined).length == content.ques.length - 1) {
        setQS(true);
      }  
    }
  };
  
  


  useEffect(() => {
    const fetchUserInfo = async () => {
      const q1 = query(userDataRef, where('id', '==', `${currentUser.uid}`));
      let temp1 = [];
      const querySnapShot1 = await getDocs(q1);
      try {
        querySnapShot1.forEach((doc) => {
          temp1.push(doc.data());
        });
        setUC(temp1[0].enrolled_Chapters);
      } catch (err) {
        console.log(err);
      } 
    };
  
    if (currentUser.uid) {
      fetchUserInfo();
    }
  }, [currentUser.uid]);
  

  useEffect(() => {
    // setContent(currentChapter.content[i]);
    const cc =  JSON.parse(localStorage.getItem('currentChapter'))
    if(cc){
        setContent(cc.content[i]);
        setLoad(true)
    }
  }, [i]);

    const HandleChapView =()=>{
    if(view == 'visible'){
      setView('hidden')
    }
  }
  const HandleChapView2 =()=>{
    if(view == 'hidden'){
      setView('visible')
    }
  }

  return (
    <div className="chapters">
          {
            windowWidth >= 768 &&
            <SideBar p = {false}/>
          }
          {
            windowWidth < 768 && 
            <NavBarMob p = {false}/>
          }
          {
                windowWidth < 768 && view == 'hidden' &&
                <div className='NavbarMob2'>
                  <button onClick={()=>{HandleChapView()}}>Chapters</button>
                  <button onClick={()=>{HandleChapView2()}} style={{backgroundColor:'#1C1C1C'}}>Extras</button>
                </div>
                
            }
            {
                windowWidth < 768 && view == 'visible' &&
                <div className='NavbarMob2'>
                  <button onClick={()=>{HandleChapView()}} style={{backgroundColor:'#1C1C1C'}}>Chapters</button>
                  <button onClick={()=>{HandleChapView2()}}>Extras</button>
                </div>
                
            }
      <div className="container">
        {content && content.type === 'vid' && (
          <div className="frame">
            <div className='cover'></div>
            {
              !fullSc && content.vidId &&
              <YouTube
                videoId={`${content.vidId}`}
                onReady={(event) => {
                  playerRef.current = event.target;
                }}
                onEnd={handleVideoEnd}
                opts={opts}
                ref = {playerRef}
              />
            }
            {
              fullSc && content.vidId &&
                <YouTube
                videoId={`${content.vidId}`}
                onReady={(event) => {
                  playerRef.current = event.target;
                }}
                onEnd={handleVideoEnd}
                opts={opts2}
                ref = {playerRef}
              />
            }
            {
              !fullSc && content.dLink &&
              <iframe
                src={`${content.dLink}`}
                width={opts.width}
                height={opts.height}
                allow="autoplay; fullscreen"
                allowFullScreen
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              ></iframe>
            }
            {
              fullSc && content.dLink &&
              <iframe
                src={`${content.dLink}`}
                width={opts2.width}
                height={opts2.height}
                allow="autoplay"
                // frameBorder="0"
                allowFullScreen
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              ></iframe>

            }
          </div>
        )}
        {content && content.type === 'game' && (
          <iframe
            title="External Website"
            src={`${content.link}`}
            className="frame"
            allow="fullscreen"
            allowFullScreen
          ></iframe>
        )}
        {content && content.type === 'checkpoint' && (
          <div className="checkpoint">
            <img src={headerImg}></img>
            <div className="main">
              <h2 className="heading">Questions</h2>
              {content.ques.map((question, questionIndex) => (
                <div className="question-box" key={questionIndex}>
                  {
                    question.type == 'matchthefollowing' && 
                    <>
                      <p className="question">
                        {questionIndex + 1}.  Match the following options
                      </p>
                      <img src={`${question.questionImg}`} className='photo' style={{width:'100%',height:'50vh'}}>
                      </img> 

                    </>
                  }
                  {question.type == 'matchthefollowing' && 
                    <div className='options2'>
                      <form onSubmit={(e)=>HandleMatchSave(e,question.opts.length,questionIndex)}>
                        {
                          question.opts.map((option,optionsIndex)=>(
                            <p>{option} → <input type='number'></input></p>
                          ))
                        }
                        <button type='submit' className='submit-btn'>Done</button>
                      </form>
                    </div>
                  }
                  {question.type === "tickthefollowing" && (
                    <p className="question">
                      {questionIndex + 1}. {question.question}
                    </p>
                  )}
                  {question.type === "tickthefollowing" && (
                    <div className="options">
                      {question.opts.map((option, optionIndex) => {
                        let optionClass = "option";
                        const currentValue = answers[questionIndex] || "";
                        const str = optionIndex.toString();

                        if (currentValue.includes(str)) {
                          optionClass += " selected";
                        }
                        {/* const isCorrectAnswer = showAns && answers[questionIndex] == question.answer; */}

                        if (showAns) {
                          if (currentValue.includes(str) && question.answer.includes(str)) {
                            optionClass += " correct";
                          } else if (currentValue.includes(str) && question.answer.includes(str)==false) {
                            optionClass += " wrong";
                          }
                        }
                        return (
                          <p
                            key={optionIndex}
                            onClick={() => HandleTickOptionClick(questionIndex, optionIndex)}
                            className={optionClass}
                          >
                            {optionIndex}. {option}
                          </p>
                        );
                      })}
                    </div>
                  )}

                  {question.type == "mcq" && 
                          <p className="question">
                            {questionIndex + 1}. {question.question}
                          </p>
                  }
                  {question.type == 'mcq' &&
                    <div className="options">
                    {question.opts.map((option, optionIndex) => {
                      const isOptionSelected = answers[questionIndex] === optionIndex;
                      const isCorrectAnswer = showAns && optionIndex === question.answer && isOptionSelected;

                      let optionClass = 'option';

                      if (isOptionSelected && !showAns) {
                        optionClass += ' selected';
                      }

                      if (showAns) {
                        if (isOptionSelected && isCorrectAnswer) {
                          optionClass += ' correct';
                        } else if (isOptionSelected && !isCorrectAnswer) {
                          optionClass += ' wrong';
                        }
                      }

                      return (
                        <p
                          key={optionIndex}
                          onClick={() => handleOptionSelect(questionIndex, optionIndex)}
                          className={optionClass}
                        >
                          {optionIndex}. {option}
                        </p>
                      );
                    })}
                    </div>
                  }
                  {showAns && question.type=='mcq' && (
                    <p className="answer">Correct Answer: {question.opts[question.answer]}</p>
                  )}
                  {showAns && question.type === 'matchthefollowing' && (
                    <p className="answer">Correct Order: {question.answer.replace(/./g, (char, index) => index === 0 ? char : `, ${char}`)}</p>
                  )}
                  {showAns && question.type === 'tickthefollowing' && (
                    <p className="answer">Correct Options: {question.answer.split('').map(index => question.opts[parseInt(index, 10)]).join(', ')}</p>
                  )}


                </div>
              ))}
              {!showAns && quizStatus && (
                <button onClick={handleSubmit} className="submit-btn">
                  Submit
                </button>
              )}
              {showAns && (
                <div className="final">
                  <button className="nav-btn" onClick={HandlePrev}>
                    ← Previous
                  </button> 
                  <button className="nav-btn" onClick={HandleNext}>
                    Next →
                  </button>
                  <p>
                    Your Total score is {score}/{content.ques.length}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}

        {content && (content.type === 'game' || content.type === 'vid') && (
          <div className="controls">
            {content.type === 'vid' &&
              <button className="nav-btn" onClick={HandlePrev} style={{width:'45%'}}>
                ← Previous
              </button> 
            }
            {
              content.type === 'game' && 
              <>
                <button className="nav-btn" onClick={HandlePrev}>
                  ← Previous
                </button> 
                <button className="nav-btn" onClick={handleFullScreen}>
                  Full Screen
                </button>
                <button className="nav-btn" onClick={HandleNext}>
                  Next →
                </button>
              </>
            }
            {content.type === 'vid' &&
              <button className="nav-btn" onClick={HandleNext} style={{width:'45%'}}>
                Next →
              </button>
            }
          </div>
        )}
      </div>
      {
        loaded && 
        <ProgressBar percentage={i} total={currentChapter.content.length} userData={userData} view = {view} chapters={ch} />
      }
    </div>
  );
}

export default Chapters;