import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../services/firebase";
import "./BlogList.scss";
import BlogAppBar from "./BlogAppBar";
// import BlogHead from "./BlogHead";
import Footer from "../Homepage/sections/Footer/Footer";
import Loader from "../../components/Loader/Loader"

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const scrollSpeed = 1;
  const scrollInterval = 20;
  const [isScrolling, setIsScrolling] = useState(true);
  const [isWideScreen, setIsWideScreen] = useState(false);
  // let scrollDirection = 1;

  useEffect(() => {
    const getBlogsFromFirebase = async () => {
      const blogsCollection = collection(db, "one-blogs");
      const response = await getDocs(blogsCollection);
      const data = response.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setBlogs(data);
    };

    getBlogsFromFirebase();
  }, []);

  useEffect(() => {
    const handleResize = async () => {
      if(window.innerWidth > 768) {
        setIsWideScreen(false);
        console.log("wide");
      } else console.log("not wide");
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {

    if(!isScrolling && !isWideScreen) return;

    const intervalId = setInterval(() => {
      if (scrollRef.current) {
        // const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

        // if (scrollTop + clientHeight >= scrollHeight || scrollTop === 0) {
        //   scrollDirection *= -1;
        // }

        scrollRef.current.scrollTop += scrollSpeed * 1;
      }
    }, scrollInterval);

    return () => clearInterval(intervalId);
  }, [isScrolling, isWideScreen]);

  useEffect(() => {

    if(!isWideScreen) return;

    const handleMouseEnter = () => setIsScrolling(false);
    const handleMouseLeave = () => setIsScrolling(true);

    const currentRef = scrollRef.current;

    if (currentRef) {
      currentRef.addEventListener('mouseenter', handleMouseEnter);
      currentRef.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('mouseenter', handleMouseEnter);
        currentRef.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [isWideScreen]);


  if (blogs.length === 0) {
    return <p><Loader/></p>;
  }

  const latestBlog = blogs[0];
  const oldBlogs = blogs.slice(1);

  return (
    <div className="blog-page">
      <BlogAppBar />
      {/* <BlogHead /> */}
      {/* <h3>
        Recent blog posts
      </h3> */}
      <div className="blogs">
        <div className="latest-blog">
          <img src={latestBlog.title_picture} alt={latestBlog.title} />
          <div className="blog-item-texts">
            <p className="blog-time">{latestBlog.date_time}</p>
            <Link to={`${latestBlog.link}`}>{latestBlog.title}</Link>
            <div>
              {latestBlog.description}
            </div>
          </div>
        </div>
        <div className="old-blogs" ref={scrollRef}>
          {oldBlogs.map((blog) => (
            <div key={blog.id} className="blog-item">
              <img src={blog.title_picture} alt={blog.title} />
              <div className="blog-item-texts">
                <p className="blog-time">{blog.date_time}</p>
                <div><Link to={`${blog.link}`}>{blog.title}</Link> </div>
                {/* &#8599;  */}
                <div className="blog-desc">
                  {blog.description}
                </div>
                {/* <div className="tags">
                  <div className="tag">lorem</div>
                  <div className="tag">ipsum</div>
                </div> */}
              </div>
            </div>
          ))}
          <div className="read-more"><button onClick={() => { navigate("/all-blogs") }}>Read more</button></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogList;
