import './howleft.scss'

import React from 'react'
import divider from '../../../../../assets/Divider.svg'
import { useState } from 'react'
import { Link } from 'react-router-dom';

function HowLeft({photo}) {

  const [windowWidth, setWindowWidth] = useState(window.outerWidth);
  return (
    <>
      {/* <Navbar /> */}
      <div className='how-left'>
            
            <img src={photo} alt="" className="ncert-image"/>
            
            <div className='how-left__title'>Our Animated Stories are game changers.</div> 
            <div className='how-left__main_title'>How One Works?</div>
      </div>
    </> 
  )
}

export default HowLeft
