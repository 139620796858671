import './primarybutton.scss'
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'



function PrimaryButton(props) {
  const navigate = useNavigate()
  
  const HandleScroll = (reference)=>{

    if(props.link){
      navigate(`${props.link}`)
    }
    else{
      console.log(reference)
        window.scrollTo({
          top: `${reference}`,
          behavior: "smooth",
        });
    }

  }
  return (
    <>
      {!props.img && 
        <button className="primary-button" style={{background : props.color, color : props.textcolor, fontsize : props.fontsize}} onClick={()=>{HandleScroll(props.reference)}}>{props.title}</button>
      }
      {props.img && 
        <button className="primary-button" style={{background : props.color, color : props.textcolor, fontsize : props.fontsize}} onClick={()=>{HandleScroll(props.reference)}}>{props.title} &nbsp; <img src={props.img}></img></button>
      }
    </>
  )
}

export default PrimaryButton